import axios from 'axios';
import moment from 'moment';
import round from 'lodash/round';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { addActivityLog } from './salesAccountsActions';
import formatAmount from '../../utils/formatAmount';
// import groupItems from '../../utils/groupItems';
import groupArrayOfObjects from '../../utils/groupArrayOfObjects';

export const GET_QUOTATIONS_LIST = 'GET_QUOTATIONS_LIST';
export const GET_QUOTATION = 'GET_QUOTATION';
export const CLEAR_QUOTATION = 'CLEAR_QUOTATION';
export const GET_QUOTATIONS = 'GET_QUOTATIONS';
export const ADD_QUOTATION = 'ADD_QUOTATION';
export const DELETE_QUOTATION = 'DELETE_QUOTATION';
export const EDIT_QUOTATION = 'EDIT_QUOTATION';
export const GET_QUOTATION_NOTE = 'GET_QUOTATION_NOTE';
export const EDIT_QUOTATION_NOTE = 'EDIT_QUOTATION_NOTE';
export const DELETE_QUOTATION_NOTE = 'DELETE_QUOTATION_NOTE';
export const ADD_QUOTATION_DOCS = 'ADD_QUOTATION_DOCS';
export const GET_QUOTATION_DOCS = 'GET_QUOTATION_DOCS';
export const DELETE_QUOTATION_DOC = 'DELETE_QUOTATION_DOC';
export const QUOTATION_LOADING = 'QUOTATION_LOADING';
export const QUOTATION_LOADED = 'QUOTATION_LOADED';

// GET QUOTATION
export const getQuotation = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/quotations/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_QUOTATION, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CHANGE QUOTATION STATUS
export const changeQuotationStatus =
  (id, status) => async (dispatch, getState) => {
    try {
      await axios.get(
        `${API_URL}/api/accounting/sales/quotations/${id}/statusUpdate?status=${status}`,
        tokenConfig(getState)
      );
      dispatch(getQuotation(id));
      dispatch(createMessage({ message: `Quotation ${status} successfully` }));
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// CLEAR QUOTATION
export const clearQuotation = () => {
  return { type: CLEAR_QUOTATION };
};

// GET QUOTATIONS LIST
export const getQuotationsList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: QUOTATION_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/list/quotations`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_QUOTATIONS_LIST, payload: res.data });
    dispatch({ type: QUOTATION_LOADED });
  } catch (err) {
    dispatch({ type: QUOTATION_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET QUOTATIONS
export const getQuotations = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/accounting/sales/quotations/`, {
      ...tokenConfig(getState),
      params,
    });
    dispatch({ type: GET_QUOTATIONS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET LATEST QUOTATION
export const getLatestQuotation = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/quotations/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// GET QUOTATION NOTE
export const getQuotationNote = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/quotations/notes/${id}/`,
      tokenConfig(getState)
    );
    // dispatch({ type: GET_QUOTATION_NOTE, payload: res.data });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// DELETE QUOTATION NOTE
export const deleteQuotationNote = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/quotations/notes/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Quotation Note Deleted' }));
    dispatch({ type: DELETE_QUOTATION_NOTE, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE QUOTATION
export const deleteQuotation = quotation => async (dispatch, getState) => {
  const newActivityLog = {
    customer_id: quotation.sales_account_id,
    activity_type: 'Quotation',
    activity_title: 'Quotation Deleted',
    module_num: quotation.quotation_num,
    amount: round(quotation.without_change_grand_total, 2),
    description: `Quotation ${quotation.quotation_formatted_number} of amount ${
      quotation.currency_symbol
    }${formatAmount(quotation.without_change_grand_total)} deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/quotations/${quotation.id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Quotation Deleted' }));
    dispatch({ type: DELETE_QUOTATION, payload: quotation.id });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// ADD QUOTATION
export const addQuotation = values => async (dispatch, getState) => {
  const modifiedQuotationItems = [];
  const quotationItems = values.quotation_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    currency: item.currency,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: item.vat_rate,
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
    cost_price: round(item.cost_price, 2),
    cost_amount_ex_vat: round(item.cost_amount_ex_vat, 2),
    cost_vat_rate: item.cost_vat_rate,
    cost_vat_amount: round(item.cost_vat_amount, 2),
    total_cost: round(item.total_cost, 2),
    profit: round(item.profit, 2),
  }));
  Object.values(groupArrayOfObjects(quotationItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedQuotationItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );
  const quotationNotes = [
    {
      note_type: 'create',
      notes: values.notes,
    },
  ];
  const quotation = {
    sales_company_id: values.sales_company_id,
    sales_account_id: values.sales_account_id,
    quotation_prefix: values.quotation_prefix,
    currency: values.currency,
    convert_to_aed: values.convert_to_aed,
    exchange_rate_of_quotation_currency: round(values.exchange_rate, 6),
    type: 'quotation',
    quotation_date:
      values.quotation_date && values.quotation_date !== ''
        ? moment(values.quotation_date).format('YYYY-MM-DD')
        : null,
    expiry_date:
      values.expiry_date && values.expiry_date !== ''
        ? moment(values.expiry_date).format('YYYY-MM-DD')
        : null,
    payment_terms: values.payment_terms,
    prepared_by: values.prepared_by,
    location: values.location,
    period_start:
      values.period_start && values.period_start !== ''
        ? moment(values.period_start).format('YYYY-MM-DD')
        : null,
    period_end:
      values.period_end && values.period_end !== ''
        ? moment(values.period_end).format('YYYY-MM-DD')
        : null,
    // show_rate_per_person: values.show_rate_per_person,
    // rate_per_person_items_description: values.items_description,
    // rate_per_person_items: values.rate_per_person_items,
    // quotation_name: values.quotation_name,
    quotation_version: values.quotation_version || 1,
    is_operational: values.is_operational,
    // number_of_persons: values.show_rate_per_person
    //   ? values.number_of_persons
    //   : 1,
    // show_emp_logo: values.show_emp_logo,
    // show_sub_total: values.show_sub_total,
    event: values.event,
    event_sorting: values.event ? values.event.toLowerCase() : '',
    terms: values.terms,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    amount_total_aed: round(values.amount_total_aed, 2),
    vat_total_aed: round(values.vat_total_aed, 2),
    grand_total_aed: round(values.grand_total_aed, 2),
    sales_person_id: values.sales_person ? values.sales_person : null,
    quotation_items: modifiedQuotationItems,
    quotation_notes: values.notes ? quotationNotes : [],
    customer_type: values.customer_type,
    tax_treatment: values.tax_treatment,
    tax_reg_num: values.tax_reg_num,
    // place_of_supply: values.place_of_supply,
    phone_num: values.phone_num,
    fax_num: values.fax_num,
    email: values.email,
    status: values.status,
    operation_cancel_reason: values.operation_cancel_reason,
    bank_account_id: values.bank_account_id,
    account_num: values.account_num,
    iban: values.iban,
    address: values.address,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
    country: values.country,
  };

  // For Operational Quotation
  if (values.rfp) quotation.rfp = values.rfp;
  if (values.parent) quotation.parent = values.parent;

  try {
    const { data } = await axios.post(
      `${API_URL}/api/accounting/sales/quotations/`,
      quotation,
      tokenConfig(getState)
    );

    const newActivityLog = {
      module_id: data.id,
      customer_id: data.sales_account_id,
      activity_type: 'Quotation',
      activity_title: 'Quotation Added',
      module_num: data.quotation_num,
      amount: round(data.without_change_grand_total, 2),
      description: `Quotation ${data.quotation_formatted_number} of amount ${
        data.currency_symbol
      }${formatAmount(data.without_change_grand_total)} created`,
    };

    dispatch(createMessage({ message: 'Quotation Added' }));
    dispatch({ type: ADD_QUOTATION, payload: data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT QUOTATION NOTE
export const editQuotationNote = values => async (dispatch, getState) => {
  const quotationNote = {
    quotation_id: values.quotation_id,
    note_type: 'update',
    notes: values.notes,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/sales/quotations/notes/${values.id}/`,
      quotationNote,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Quotation Note Updated' }));
    dispatch({ type: EDIT_QUOTATION_NOTE, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT QUOTATION
export const editQuotation = values => async (dispatch, getState) => {
  const modifiedQuotationItems = [];
  const quotationItems = values.quotation_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    currency: item.currency,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: item.vat_rate,
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
    cost_price: round(item.cost_price, 2),
    cost_amount_ex_vat: round(item.cost_amount_ex_vat, 2),
    cost_vat_rate: item.cost_vat_rate,
    cost_vat_amount: round(item.cost_vat_amount, 2),
    total_cost: round(item.total_cost, 2),
    profit: round(item.profit, 2),
  }));

  Object.values(groupArrayOfObjects(quotationItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedQuotationItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );
  const quotationNotes = [
    {
      quotation_id: values.id,
      note_type: 'update',
      notes: values.notes,
    },
  ];
  const quotation = {
    sales_company_id: values.sales_company_id,
    sales_account_id: values.sales_account_id,
    quotation_prefix: values.quotation_prefix,
    currency: values.currency,
    convert_to_aed: values.convert_to_aed,
    exchange_rate_of_quotation_currency: round(values.exchange_rate, 6),
    type: 'quotation',
    quotation_date:
      values.quotation_date && values.quotation_date !== ''
        ? moment(values.quotation_date).format('YYYY-MM-DD')
        : null,
    expiry_date:
      values.expiry_date && values.expiry_date !== ''
        ? moment(values.expiry_date).format('YYYY-MM-DD')
        : null,
    // quotation_name: values.quotation_name,
    quotation_version: values.quotation_version || 1,
    payment_terms: values.payment_terms,
    prepared_by: values.prepared_by,
    location: values.location,
    period_start:
      values.period_start && values.period_start !== ''
        ? moment(values.period_start).format('YYYY-MM-DD')
        : null,
    period_end:
      values.period_end && values.period_end !== ''
        ? moment(values.period_end).format('YYYY-MM-DD')
        : null,
    // show_rate_per_person: values.show_rate_per_person,
    // rate_per_person_items_description: values.show_rate_per_person
    //   ? values.items_description
    //   : '',
    // rate_per_person_items: values.show_rate_per_person
    //   ? values.rate_per_person_items
    //   : [],
    is_operational: values.is_operational,
    // number_of_persons: values.show_rate_per_person
    //   ? values.number_of_persons
    //   : 1,
    // show_emp_logo: values.show_emp_logo,
    // show_sub_total: values.show_sub_total,
    event: values.event,
    event_sorting: values.event ? values.event.toLowerCase() : '',
    terms: values.terms,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    amount_total_aed: round(values.amount_total_aed, 2),
    vat_total_aed: round(values.vat_total_aed, 2),
    grand_total_aed: round(values.grand_total_aed, 2),
    sales_person_id: values.sales_person ? values.sales_person : null,
    quotation_items: modifiedQuotationItems,
    quotation_notes: values.notes ? quotationNotes : [],
    customer_type: values.customer_type,
    tax_treatment: values.tax_treatment,
    tax_reg_num: values.tax_reg_num,
    // place_of_supply: values.place_of_supply,
    phone_num: values.phone_num,
    fax_num: values.fax_num,
    email: values.email,
    operation_cancel_reason: values.operation_cancel_reason,
    bank_account_id: values.bank_account_id,
    account_num: values.account_num,
    iban: values.iban,
    address: values.address,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
    country: values.country,
    operation_status: 'Edited',
    status: 'draft', // Status will be changed to draft when edited
  };

  try {
    const { data } = await axios.put(
      `${API_URL}/api/accounting/sales/quotations/${values.id}/`,
      quotation,
      tokenConfig(getState)
    );

    const newActivityLog = {
      module_id: data.id,
      customer_id: data.sales_account_id,
      activity_type: 'Quotation',
      activity_title: 'Quotation Updated',
      module_num: data.quotation_num,
      amount: round(data.grand_total, 2),
      description: `Quotation ${data.quotation_num}-${
        data.customer_type_suffix
      }-${data.quotation_prefix} of amount ${
        data.currency_symbol
      }${formatAmount(data.grand_total)} updated`,
    };

    dispatch(createMessage({ message: 'Quotation Updated' }));
    dispatch({ type: EDIT_QUOTATION, payload: data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// UPLOAD QUOTATION FILE
export const uploadQuotationFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/sales/quotations/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_QUOTATION_DOCS, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// GET QUOTATION DOCS
export const getQuotationDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/quotations/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_QUOTATION_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE QUOTATION DOC
export const deleteQuotationDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/quotations/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Quotation Doc Deleted' }));
    dispatch({ type: DELETE_QUOTATION_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

import React, { useState } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

function AccountantSidebar({ handleClick }) {
  //  const history = useHistory();
  const location = useLocation();
  const [isSubSidebarOpen, setIsSubSidebarOpen] = useState(false);
  const { companyDetail = {}, user } = useSelector(state => state.auth);
  const toggleSubSidebar = () => setIsSubSidebarOpen(!isSubSidebarOpen);

  // removed previous logic causing white page error and implemented below one with search param.

  const urlToGoBack = location.search
    ? `${location.pathname}${location.search}`
    : location.pathname;

  const goBackURL = localStorage.getItem('urlToGoBack');

  return (
    <>
      {isSubSidebarOpen ? (
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={isSubSidebarOpen}
            addEndListener={(node, done) =>
              node.addEventListener('transitionend', done, false)
            }
            classNames="fade"
          >
            <>
              <ul className="sidebar__block">
                <NavLink
                  to={goBackURL}
                  onClick={() => {
                    //  const urlToGoBack = localStorage.getItem('urlToGoBack');
                    localStorage.removeItem('urlToGoBack', '');
                    toggleSubSidebar();
                    // history.push not working
                    // history.push(urlToGoBack);
                    // // Temporary purpose
                    // window.location.href = urlToGoBack;
                  }}
                >
                  <li className="sidebar__link custom-sidebar-link">
                    <span className="sidebar__link-icon lnr lnr-arrow-left" />
                    <p className="sidebar__link-title">Back</p>
                  </li>
                </NavLink>
              </ul>
              <ul className="sidebar__block">
                {user.isAccountingSupervisor && (
                  <SidebarLink
                    title="Employee Salaries"
                    route="/pages/accounting/salaries"
                    icon="money-bill-alt"
                    onClick={handleClick}
                  />
                )}
                {!user.isAccountingStaff && (
                  <SidebarLink
                    title="Employee Leaves"
                    route="/pages/hr/leaves"
                    icon="file-alt"
                    onClick={handleClick}
                  />
                )}
                <SidebarLink
                  title="Team Meeting"
                  route="/pages/manageInternalMeetings"
                  onClick={handleClick}
                  icon="handshake"
                />
                <SidebarLink
                  icon="folder-open"
                  title="Document Storage"
                  route="/pages/storage/main"
                  onClick={handleClick}
                />
                <SidebarLink
                  icon="trash-alt"
                  title="Trashed Documents"
                  route="/pages/storage/trash"
                  onClick={handleClick}
                />
              </ul>
            </>
          </CSSTransition>
        </SwitchTransition>
      ) : (
        <>
          <ul className="sidebar__block">
            <SidebarLink
              title="Dashboard"
              icon="tachometer-alt"
              route="/"
              onClick={handleClick}
            />
          </ul>
          <ul className="sidebar__block">
            {!user.isAccountingStaff && (
              <SidebarLink
                title="Items"
                icon="shopping-basket"
                route="/pages/accounting/items"
                onClick={handleClick}
              />
            )}
            {companyDetail.is_inventory && (
              <SidebarLink
                title="Inventory Adjustments"
                icon="cubes"
                route="/pages/accounting/inventoryAdjustments"
                onClick={handleClick}
              />
            )}
            {!user.isAccountingStaff && (
              <SidebarLink
                title="Banking"
                icon="university"
                route="/pages/accounting/banking"
                onClick={handleClick}
              />
            )}

            {/* {isImmediateSupervisor && (
              <SidebarLink
                title="Employee Leave"
                icon="th-list"
                route="/pages/employee/leaves"
                onClick={handleClick}
              />
            )} */}
            <SidebarCategory title="Sales" icon="gifts">
              <SidebarLink
                title="Accounts"
                route="/pages/accounting/sales/accounts"
                onClick={handleClick}
              />
              <SidebarLink
                title="Customers"
                route="/pages/accounting/sales/customers"
                onClick={handleClick}
              />
              <SidebarLink
                title="Quotations"
                route="/pages/accounting/sales/quotations"
                onClick={handleClick}
              />
              <SidebarLink
                title="Proforma Invoices"
                route="/pages/accounting/sales/proInvoices"
                onClick={handleClick}
              />
              <SidebarLink
                title="Invoices"
                route="/pages/accounting/sales/invoices"
                onClick={handleClick}
              />
              <SidebarLink
                title="Payments Received"
                route="/pages/accounting/sales/paymentsReceived"
                onClick={handleClick}
              />
              <SidebarLink
                title="Credit Notes"
                route="/pages/accounting/sales/creditNotes"
                onClick={handleClick}
              />
            </SidebarCategory>
            <SidebarCategory title="Purchases" icon="shopping-bag">
              <SidebarLink
                title="Suppliers"
                route="/pages/accounting/purchases/suppliers"
                onClick={handleClick}
              />
              <SidebarLink
                title="Expenses"
                route="/pages/accounting/purchases/expenses"
                onClick={handleClick}
              />
              <SidebarLink
                title="Purchase Orders"
                route="/pages/accounting/purchases/purOrders"
                onClick={handleClick}
              />
              <SidebarLink
                title="Bills"
                route="/pages/accounting/purchases/bills"
                onClick={handleClick}
              />
              <SidebarLink
                title="Payments Made"
                route="/pages/accounting/purchases/paymentsMade"
                onClick={handleClick}
              />
              <SidebarLink
                title="Debit Notes"
                route="/pages/accounting/purchases/supplierCredits"
                onClick={handleClick}
              />
            </SidebarCategory>
            <SidebarCategory title="Accountant" icon="user-tie">
              {!user.isAccountingStaff && (
                <SidebarLink
                  title="Currencies"
                  route="/pages/accounting/accountant/currencies"
                  onClick={handleClick}
                />
              )}
              <SidebarLink
                title="Manual Journals"
                route="/pages/accounting/accountant/journals"
                onClick={handleClick}
              />
              {!user.isAccountingStaff && (
                <>
                  <SidebarLink
                    title="Tax Payments"
                    route="/pages/accounting/accountant/taxPayments"
                    onClick={handleClick}
                  />

                  <SidebarLink
                    title="Chart of Accounts"
                    route="/pages/accounting/accountant/chartOfAccounts"
                    onClick={handleClick}
                  />

                  <SidebarLink
                    title="Transaction Locking"
                    route="/pages/accounting/accountant/transactionLocking"
                    onClick={handleClick}
                  />
                </>
              )}
            </SidebarCategory>
            <SidebarLink
              title="Reports"
              route="/pages/reports"
              icon="project-diagram"
              onClick={handleClick}
            />
          </ul>
          <ul className="sidebar__block">
            <NavLink
              to={urlToGoBack}
              onClick={() => {
                localStorage.setItem('urlToGoBack', urlToGoBack);
                toggleSubSidebar();
              }}
            >
              <li className="sidebar__link">
                <span className="sidebar__link-icon fas fa-layer-group" />
                <p className="sidebar__link-title">Miscellaneous</p>
              </li>
            </NavLink>
          </ul>
        </>
      )}
    </>
  );
}

AccountantSidebar.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default AccountantSidebar;

import {
  GET_USER,
  GET_USERS,
  GET_USERS_BY_STATUS,
  ADD_USER,
  DELETE_USER,
  CLEAR_USER,
  CLEAR_USERS,
  GET_RECENT_ACTIVITY,
  RECORD_ACTIVITY,
  GET_USERS_BY_DEPARTMENT,
  GET_USER_UPLOADED_DOCUMENT,
  UPDATE_USER_PROFILE,
} from '../actions/usersActions';

const initialState = {
  users: [],
  userDocuments: [],
  usersByStatus: [],
  usersByDepartment: [],
  statuses: {
    active: 'Active',
    resigned: 'Resigned',
    terminated: 'Terminated',
  },
  fetched: null,
  recentActivity: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        fetched: action.payload,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        fetched: action.payload.user,
      };
    case GET_USERS_BY_STATUS:
      return {
        ...state,
        usersByStatus: action.payload,
      };
    case GET_USERS_BY_DEPARTMENT:
      return {
        ...state,
        usersByDepartment: action.payload,
      };

    case GET_USER_UPLOADED_DOCUMENT:
      return {
        ...state,
        userDocuments: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload
          .filter(user => user.profile && user.profile.id)
          .map(user => ({
            id: user.id,
            employee_name: user.profile.employee_name,
            job_title: user.profile.job_title,
            status: user.profile.status,
            department: user.profile.department,
            profile: user.profile,
          })),
      };
    case CLEAR_USERS:
      return {
        ...state,
        users: [],
      };
    case ADD_USER:
      return {
        ...state,
        users: [
          ...state.users,
          {
            id: action.payload.id,
            employee_name: action.payload.profile.employee_name,
            status: action.payload.profile.status,
            job_title: action.payload.profile.job_title,
            department: action.payload.profile.department,
          },
        ],
      };
    case DELETE_USER:
      return {
        ...state,
        usersByStatus: state.usersByStatus.filter(
          user => user.id !== action.payload
        ),
      };
    case GET_RECENT_ACTIVITY:
      return {
        ...state,
        recentActivity: action.payload,
      };
    case RECORD_ACTIVITY:
      return {
        ...state,
        recentActivity: [action.payload, ...state.recentActivity],
      };
    case CLEAR_USER:
      return {
        ...state,
        fetched: null,
      };
    default:
      return state;
  }
};

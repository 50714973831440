import axios from 'axios';
import moment from 'moment';
import { round } from 'lodash/math';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { addSupplierActivityLog } from './suppliersActions';
import formatAmount from '../../utils/formatAmount';
import groupArrayOfObjects from '../../utils/groupArrayOfObjects';

export const GET_PUR_ORDERS_LIST = 'GET_PUR_ORDERS_LIST';
export const GET_PUR_ORDERS = 'GET_PUR_ORDERS';
export const GET_PUR_ORDER = 'GET_PUR_ORDER';
export const CLEAR_PUR_ORDER = 'CLEAR_PUR_ORDER';
export const ADD_PUR_ORDER = 'ADD_PUR_ORDER';
export const DELETE_PUR_ORDER = 'DELETE_PUR_ORDER';
export const EDIT_PUR_ORDER = 'EDIT_PUR_ORDER';
export const GET_PUR_ORDER_NOTE = 'GET_PUR_ORDER_NOTE';
export const EDIT_PUR_ORDER_NOTE = 'EDIT_PUR_ORDER_NOTE';
export const DELETE_PUR_ORDER_NOTE = 'DELETE_PUR_ORDER_NOTE';
export const GET_PUR_ORDER_DOCS = 'GET_PUR_ORDER_DOCS';
export const ADD_PUR_ORDER_DOCS = 'ADD_PUR_ORDER_DOCS';
export const DELETE_PUR_ORDER_DOC = 'DELETE_PUR_ORDER_DOC';
export const PUR_ORDERS_LOADING = 'PUR_ORDERS_LOADING';
export const PUR_ORDERS_LOADED = 'PUR_ORDERS_LOADED';
export const GET_PUR_ORDERS_BY_SUPPLIER = 'GET_PUR_ORDERS_BY_SUPPLIER';
export const CLEAR_SUPPLIER_PUR_ORDERS = 'CLEAR_SUPPLIER_PUR_ORDERS';

// GET PUR ORDER
export const getPurOrder = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/purOrders/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_PUR_ORDER, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR PUR ORDER
export const clearPurOrder = () => {
  return { type: CLEAR_PUR_ORDER };
};

// GET PUR ORDERS LIST
export const getPurOrdersList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: PUR_ORDERS_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/list/purOrder`,
      { ...tokenConfig(getState), params }
    );
    dispatch({ type: GET_PUR_ORDERS_LIST, payload: res.data });
    dispatch({ type: PUR_ORDERS_LOADED });
  } catch (err) {
    dispatch({ type: PUR_ORDERS_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET PUR ORDERS
export const getPurOrders = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/purOrders/`,
      { ...tokenConfig(getState), params }
    );
    dispatch({ type: GET_PUR_ORDERS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET LATEST PUR ORDER
export const getLatestPurOrder = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/purOrders/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// GET PUR ORDER NOTE
export const getPurOrderNote = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/purOrders/notes/${id}/`,
      tokenConfig(getState)
    );
    // dispatch({ type: GET_PUR_ORDER_NOTE, payload: res.data });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// DELETE PUR ORDER NOTE
export const deletePurOrderNote = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/purOrders/notes/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Purchase Order Note Deleted' }));
    dispatch({ type: DELETE_PUR_ORDER_NOTE, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE PUR ORDER
export const deletePurOrder = purOrder => async (dispatch, getState) => {
  const newActivityLog = {
    supplier_id: purOrder.supplier_id,
    activity_type: 'Purchase Order',
    activity_title: 'Purchase Order Deleted',
    module_num: purOrder.pur_order_num,
    amount: round(purOrder.without_change_grand_total, 2),
    description: `Purchase Order ${
      purOrder.pur_order_formatted_number
    } of amount ${purOrder.currency_symbol}${formatAmount(
      purOrder.without_change_grand_total
    )} deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/purOrders/${purOrder.id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Purchase Order Deleted' }));
    dispatch({ type: DELETE_PUR_ORDER, payload: purOrder.id });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// ADD PUR ORDER
export const addPurOrder = values => async (dispatch, getState) => {
  const modifiedPurOrderItems = [];
  const purOrderItems = values.pur_order_items.map((item, index) => ({
    service_type: item.service_type,
    service_type_name: item.service_type + index + 1,
    description: item.description,
    currency: item.currency,
    expense_account: item.chart_of_account_id,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: round(item.vat_rate, 2),
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
  }));

  Object.values(groupArrayOfObjects(purOrderItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedPurOrderItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );

  const purOrderNotes = [
    {
      note_type: 'create',
      notes: values.notes,
    },
  ];
  const purOrder = {
    supplier_id: parseInt(values.supplier, 10),
    tax_treatment: values.tax_treatment,
    tax_registration_number: values.trn,
    place_of_supply: values.place_of_supply,
    event: values.event,
    event_sorting: values.event ? values.event.toLowerCase() : '',
    pur_order_suffix: values.pur_order_suffix,
    currency: values.currency,
    convert_to_aed: values.convert_to_aed,
    exchange_rate_of_purorder_currency: round(values.exchange_rate, 6),
    type: 'pur_order', // added type key for transection locking feature.
    pur_order_date:
      values.pur_order_date && values.pur_order_date !== ''
        ? moment(values.pur_order_date).format('YYYY-MM-DD')
        : null,
    location: values.location,
    period_start:
      values.period_start && values.period_start !== ''
        ? moment(values.period_start).format('YYYY-MM-DD')
        : null,
    period_end:
      values.period_end && values.period_end !== ''
        ? moment(values.period_end).format('YYYY-MM-DD')
        : null,
    // requested_by: values.requested_by,
    // prepared_by: values.prepared_by,
    // sales_person_id: values.sales_person_id,
    // type_of_customer: values.type_of_customer,
    show_stamp: values.show_stamp,
    stamp: values.stamp,
    requestor_signature_show: values.requestor_signature_show,
    requestor_signature: values.requestor_signature,
    requestor_name: values.requestor_name,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    discount: values.discount,
    account_num: values.account_num,
    iban: values.iban,
    advance_pyments: values.advance_pyments,
    payment_notes: values.payment_notes,
    address: values.address,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
    country: values.country,
    pur_order_items: modifiedPurOrderItems,
    pur_order_notes: values.notes ? purOrderNotes : [],
    aed_amount: 0,
  };

  // if (values.deliver_to === 'customer' && values.sales_account_id) {
  //   purOrder.sales_account_id = values.sales_account_id;
  // }

  try {
    const { data } = await axios.post(
      `${API_URL}/api/accounting/purchases/purOrders/`,
      purOrder,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Purchase Order Added' }));
    dispatch({ type: ADD_PUR_ORDER, payload: data });

    const newActivityLog = {
      module_id: data.id,
      supplier_id: data.supplier_id,
      activity_type: 'Purchase Order',
      activity_title: 'Purchase Order Added',
      module_num: data.pur_order_num,
      amount: round(data.without_change_grand_total, 2),
      description: `Purchase Order ${
        data.pur_order_formatted_number
      }  of amount ${data.currency_symbol}${formatAmount(
        data.without_change_grand_total
      )} created`,
    };

    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT PUR ORDER NOTE
export const editPurOrderNote = values => async (dispatch, getState) => {
  const purOrderNote = {
    pur_order_id: values.pur_order_id,
    note_type: 'update',
    notes: values.notes,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/purchases/purOrders/notes/${values.id}/`,
      purOrderNote,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Purchase Order Note Updated' }));
    dispatch({ type: EDIT_PUR_ORDER_NOTE, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT PUR ORDER
export const editPurOrder = values => async (dispatch, getState) => {
  const modifiedPurOrderItems = [];
  const purOrderItems = values.pur_order_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    currency: item.currency,
    expense_account: item.chart_of_account_id,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: round(item.vat_rate, 2),
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
  }));
  Object.values(groupArrayOfObjects(purOrderItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedPurOrderItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );
  const purOrderNotes = [
    {
      note_type: 'update',
      notes: values.notes,
    },
  ];
  const purOrder = {
    supplier_id: values.supplier,
    tax_treatment: values.tax_treatment,
    tax_registration_number: values.trn,
    place_of_supply: values.place_of_supply,
    event: values.event,
    event_sorting: values.event ? values.event.toLowerCase() : '',
    pur_order_suffix: values.pur_order_suffix,
    currency: values.currency,
    convert_to_aed: values.convert_to_aed,
    exchange_rate_of_purorder_currency: round(values.exchange_rate, 6),
    type: 'pur_order', // added type key for transection locking feature.
    pur_order_date:
      values.pur_order_date && values.pur_order_date !== ''
        ? moment(values.pur_order_date).format('YYYY-MM-DD')
        : null,
    location: values.location,
    period_start:
      values.period_start && values.period_start !== ''
        ? moment(values.period_start).format('YYYY-MM-DD')
        : null,
    period_end:
      values.period_end && values.period_end !== ''
        ? moment(values.period_end).format('YYYY-MM-DD')
        : null,
    // requested_by: values.requested_by,
    // prepared_by: values.prepared_by,
    // sales_person_id: values.sales_person_id,
    // type_of_customer: values.type_of_customer,
    requestor_signature_show: values.requestor_signature_show,
    requestor_name: values.requestor_name,
    stamp: values.stamp,
    requestor_signature: values.requestor_signature,
    show_stamp: values.show_stamp,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    discount: values.discount,
    account_num: values.account_num,
    iban: values.iban,
    advance_pyments: values.advance_pyments,
    payment_notes: values.payment_notes,
    address: values.address,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
    country: values.country,
    pur_order_items: modifiedPurOrderItems,
    pur_order_notes: values.notes ? purOrderNotes : [],
    aed_amount: 0,
  };

  // if (values.deliver_to === 'customer' && values.sales_account_id) {
  //   purOrder.sales_account_id = values.sales_account_id;
  // }

  try {
    const { data } = await axios.put(
      `${API_URL}/api/accounting/purchases/purOrders/${values.id}/`,
      purOrder,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Purchase Order Updated' }));
    dispatch({ type: EDIT_PUR_ORDER, payload: data });

    const newActivityLog = {
      module_id: data.id,
      supplier_id: data.supplier_id,
      activity_type: 'Purchase Order',
      activity_title: 'Purchase Order Updated',
      module_num: data.pur_order_num,
      amount: round(data.without_change_grand_total, 2),
      description: `Purchase Order ${
        data.pur_order_formatted_number
      }  of amount ${data.currency_symbol}${formatAmount(
        data.without_change_grand_total
      )} updated`,
    };

    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// UPLOAD PURCHASE ORDER FILE
export const uploadPurOrderFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/purOrders/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_PUR_ORDER_DOCS, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// GET PURCHASE ORDER DOCS
export const getPurOrderDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/purOrders/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_PUR_ORDER_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE PURCHASE ORDER DOC
export const deletePurOrderDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/purOrders/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Purchase Order Doc Deleted' }));
    dispatch({ type: DELETE_PUR_ORDER_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const markPurOrderIssued = id => async (dispatch, getState) => {
  try {
    await axios.get(
      `${API_URL}/api/accounting/purchases/purOrder/${id}/issued`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Successfully marked as issued' }));
    dispatch(getPurOrder(id));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const getPurOrdersBySupplierId =
  supplierId => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/purchases/bills/${supplierId}/purorder`,
        tokenConfig(getState)
      );
      dispatch({ type: GET_PUR_ORDERS_BY_SUPPLIER, payload: res.data });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const clearSupplierPurOrders = () => ({
  type: CLEAR_SUPPLIER_PUR_ORDERS,
});

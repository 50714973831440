import axios from 'axios';
import moment from 'moment';
import { round } from 'lodash/math';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { addSupplierActivityLog } from './suppliersActions';
import groupArrayOfObjects from '../../utils/groupArrayOfObjects';
import formatAmount from '../../utils/formatAmount';

export const GET_BILLS_LIST = 'GET_BILLS_LIST';
export const GET_BILLS = 'GET_BILLS';
export const GET_BILL = 'GET_BILL';
export const CLEAR_BILL = 'CLEAR_BILL';
export const ADD_BILL = 'ADD_BILL';
export const DELETE_BILL = 'DELETE_BILL';
export const VOID_BILL = 'VOID_BILL';
export const EDIT_BILL = 'EDIT_BILL';
export const ADD_BILL_DOC = 'ADD_BILL_DOC';
export const GET_BILL_DOCS = 'GET_BILL_DOCS';
export const DELETE_BILL_DOC = 'DELETE_BILL_DOC';
export const MARK_BILL_OPEN = 'MARK_BILL_OPEN';
export const GET_NON_DRAFT_BILLS = 'GET_NON_DRAFT_BILLS';
export const GET_BILL_JOURNAL = 'GET_BILL_JOURNAL';
export const CLEAR_BILL_JOURNAL = 'CLEAR_BILL_JOURNAL';
export const GET_BILL_PAYMENTS = 'GET_BILL_PAYMENTS';
export const DELETE_BILL_CREDIT_APPLIED_PAYMENT =
  'DELETE_BILL_CREDIT_APPLIED_PAYMENT';
export const CLEAR_BILL_PAYMENTS = 'CLEAR_BILL_PAYMENTS';
export const BILLS_LOADING = 'BILLS_LOADING';
export const BILLS_LOADED = 'BILLS_LOADED';
export const GET_BILL_NOTE = 'GET_BILL_NOTE';
export const EDIT_BILL_NOTE = 'EDIT_BILL_NOTE';
export const DELETE_BILL_NOTE = 'DELETE_BILL_NOTE';

// GET LATEST BILL REFERENCE NUMBER
export const getLatestBillReferenceNumber =
  () => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/purchases/billReferenceNum/latest `,
        tokenConfig(getState)
      );
      return res.data;
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
      return null;
    }
  };

// GET BILL
export const getBill = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/bills/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_BILL,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
export const voidBill = bill => async (dispatch, getState) => {
  const newActivityLog = {
    module_id: bill.billId,
    supplier_id: bill.supplierId,
    activity_type: 'Bill',
    activity_title: 'Bill Updated',
    module_num: bill.billNum,
    amount: round(bill.withoutChangeGrandTotal, 2),
    description: `Bill ${bill.billNum} of amount ${
      bill.currencySymbol
    }${formatAmount(bill.withoutChangeGrandTotal)} status updated to Void`,
  };
  try {
    const { data } = await axios.get(
      `${API_URL}/api/accounting/purchases/bill/${bill.billId}/status?reason=${bill.reason}`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Status Updated' }));
    dispatch({
      type: VOID_BILL,
      payload: data,
    });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR BILL
export const clearBill = () => {
  return {
    type: CLEAR_BILL,
  };
};

// GET BILLS LIST
export const getBillsList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: BILLS_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/list/bills`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({
      type: GET_BILLS_LIST,
      payload: res.data,
    });
    dispatch({ type: BILLS_LOADED });
  } catch (err) {
    dispatch({ type: BILLS_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET BILLS
export const getBills = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/accounting/purchases/bills/`, {
      ...tokenConfig(getState),
      params,
    });
    dispatch({
      type: GET_BILLS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ACTIVE BILLS
export const getActiveBills = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/bills/active`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_BILLS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE BILL
export const deleteBill = bill => async (dispatch, getState) => {
  const newActivityLog = {
    supplier_id: bill.supplier_id,
    activity_type: 'Bill',
    activity_title: 'Bill Deleted',
    module_num: bill.bill_num,
    amount: round(bill.without_change_grand_total, 2),
    description: `Bill ${bill.bill_num} of amount ${
      bill.currency_symbol
    }${formatAmount(bill.without_change_grand_total)} deleted`,
  };
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/bills/${bill.id}/`,
      {
        ...tokenConfig(getState),
        data: {
          type: 'bill',
          bill_date: bill.bill_date,
        },
      }
    );
    dispatch(createMessage({ message: 'Bill Deleted' }));
    dispatch({
      type: DELETE_BILL,
      payload: bill.id,
    });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD BILL
export const addBill = values => async (dispatch, getState) => {
  const modifiedBillItems = [];
  const billItems = values.bill_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    currency: item.currency,
    chart_of_account: item.chart_of_account_id,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: round(item.vat_rate, 2),
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
  }));
  Object.values(groupArrayOfObjects(billItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedBillItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );

  const billNotes = [
    {
      note_type: 'create',
      notes: values.notes,
    },
  ];

  const bill = {
    supplier_id: values.supplier,
    tax_treatment: values.tax_treatment,
    trn: values.trn,
    place_of_supply: values.place_of_supply,
    bill_num: values.bill_num,
    reference_num: values.reference_num,
    order_num: values.order_num,
    permit_num: values.permit_num,
    //  type_of_customer: values.type_of_customer,
    period_start: moment(values.period_start).format('YYYY-MM-DD'),
    period_end: moment(values.period_end).format('YYYY-MM-DD'),
    currency: values.currency,
    exchange_rate_of_bill_currency: round(values.exchange_rate, 6),
    bill_date:
      values.bill_date && values.bill_date !== ''
        ? moment(values.bill_date).format('YYYY-MM-DD')
        : null,
    due_date:
      values.due_date && values.due_date !== ''
        ? moment(values.due_date).format('YYYY-MM-DD')
        : null,
    payment_terms: values.payment_terms,
    is_tax_inclusive: values.is_tax_inclusive,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    discount: values.discount,
    aed_amount: 0.0,
    bill_items: modifiedBillItems,
    bill_notes: values.notes ? billNotes : [],
    type: 'bill',
  };

  if (values.status) {
    bill.status = values.status;
  }

  if (values.pur_order_id) {
    bill.pur_order_id = values.pur_order_id;
  }

  try {
    const { data } = await axios.post(
      `${API_URL}/api/accounting/purchases/bills/`,
      bill,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Bill Added' }));
    dispatch({
      type: ADD_BILL,
      payload: data,
    });
    const newActivityLog = {
      module_id: data.id,
      supplier_id: data.supplier_id,
      activity_type: 'Bill',
      activity_title: 'Bill Added',
      module_num: data.bill_num,
      amount: round(data.without_change_grand_total, 2),
      description: `Bill ${data.bill_num} of amount ${
        data.currency_symbol
      }${formatAmount(data.without_change_grand_total)} created`,
    };

    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT BILL
export const editBill = values => async (dispatch, getState) => {
  const modifiedBillItems = [];
  const billItems = values.bill_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    currency: item.currency,
    chart_of_account: item.chart_of_account_id,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: round(item.vat_rate, 2),
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
  }));
  Object.values(groupArrayOfObjects(billItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedBillItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );

  const billNotes = [
    {
      note_type: 'update',
      notes: values.notes,
    },
  ];

  const bill = {
    supplier_id: values.supplier,
    tax_treatment: values.tax_treatment,
    trn: values.trn,
    place_of_supply: values.place_of_supply,
    bill_num: values.bill_num,
    reference_num: values.reference_num,
    order_num: values.order_num,
    permit_num: values.permit_num,
    // type_of_customer: values.type_of_customer,
    period_start: moment(values.period_start).format('YYYY-MM-DD'),
    period_end: moment(values.period_end).format('YYYY-MM-DD'),
    currency: values.currency,
    exchange_rate_of_bill_currency: round(values.exchange_rate, 6),
    bill_date:
      values.bill_date && values.bill_date !== ''
        ? moment(values.bill_date).format('YYYY-MM-DD')
        : null,
    due_date:
      values.due_date && values.due_date !== ''
        ? moment(values.due_date).format('YYYY-MM-DD')
        : null,
    payment_terms: values.payment_terms,
    is_tax_inclusive: values.is_tax_inclusive,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    discount: values.discount,
    aed_amount: 0.0,
    bill_items: modifiedBillItems,
    bill_notes: values.notes ? billNotes : [],
    type: 'bill',
  };

  if (values.status) {
    bill.status = values.status;
  }

  if (values.pur_order_id) {
    bill.pur_order_id = values.pur_order_id;
  }

  try {
    const { data } = await axios.put(
      `${API_URL}/api/accounting/purchases/bills/${values.id}/`,
      bill,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Bill Updated' }));
    dispatch({
      type: EDIT_BILL,
      payload: data,
    });

    const newActivityLog = {
      module_id: data.id,
      supplier_id: data.supplier_id,
      activity_type: 'Bill',
      activity_title: 'Bill Added',
      module_num: data.bill_num,
      amount: round(data.without_change_grand_total, 2),
      description: `Bill ${data.bill_num} of amount ${
        data.currency_symbol
      }${formatAmount(data.without_change_grand_total)} updated`,
    };

    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// UPLOAD BILL FILE
export const uploadBillFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/bills/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_BILL_DOC, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// GET BILL DOCS
export const getBillDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/bills/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_BILL_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE BILL DOC
export const deleteBillDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/bills/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Bill Doc Deleted' }));
    dispatch({ type: DELETE_BILL_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// MARK BILL AS OPEN
export const markBillAsOpen = bill => async (dispatch, getState) => {
  const newActivityLog = {
    module_id: bill.id,
    supplier_id: bill.supplier_id,
    activity_type: 'Bill',
    activity_title: 'Bill Updated',
    module_num: bill.bill_num,
    amount: round(bill.grand_total, 2),
    description: `Bill "${bill.bill_num}" status changed from draft to open`,
  };

  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/bills/${bill.id}/open`,
      tokenConfig(getState)
    );
    dispatch({
      type: MARK_BILL_OPEN,
      payload: res.data,
    });
    dispatch(createMessage({ message: 'Bill successfully converted to open' }));
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// GET NON DRAFT BILLS BY SUPPLIER ID
export const getNonDraftBillsOfSupplier = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/accounts/${id}/nonDraftBills`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_NON_DRAFT_BILLS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET BILL
export const getBillJournal = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/bill/${id}/journals`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_BILL_JOURNAL,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const clearBillJournal = () => ({ type: CLEAR_BILL_JOURNAL });

// GET BILL PAYMENTS
export const getBillPayments = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/bills/${id}/payment`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_BILL_PAYMENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
export const deleteBillCreditAppliedPayment =
  (billId, id) => async (dispatch, getState) => {
    try {
      await axios.delete(
        `${API_URL}/api/accounting/purchases/bills/${billId}/payment?delete=${id}`,
        tokenConfig(getState)
      );
      dispatch({
        type: DELETE_BILL_CREDIT_APPLIED_PAYMENT,
        payload: id,
      });
      dispatch(getBill(billId));
      dispatch(createMessage({ message: 'Credit Applied Deleted ' }));
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const clearBillPayments = () => ({ type: CLEAR_BILL_PAYMENTS });

// GET BILL NOTE
export const getBillNote = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/bills/notes/${id}/`,
      tokenConfig(getState)
    );
    // dispatch({ type: GET_BILL_NOTE, payload: res.data });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// DELETE BILL NOTE
export const deleteBillNote = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/bills/notes/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Bill Note Deleted' }));
    dispatch({ type: DELETE_BILL_NOTE, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// EDIT BILL NOTE
export const editBillNote = values => async (dispatch, getState) => {
  const billNote = {
    bill_id: values.bill_id,
    note_type: 'update',
    notes: values.notes,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/purchases/bills/notes/${values.id}/`,
      billNote,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Bill Note Updated' }));
    dispatch({ type: EDIT_BILL_NOTE, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

import axios from 'axios';
import moment from 'moment';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';
import { tokenConfig } from './authActions';

export const GET_LEAVES = 'GET_LEAVES';
export const GET_USER_LEAVES = 'GET_USER_LEAVES';
export const GET_FILTERED_LEAVES = 'GET_FILTERED_LEAVES';
export const GET_USER_LEAVES_NUMBERS = 'GET_USER_LEAVES_NUMBERS';
export const DELETE_LEAVE = 'DELETE_LEAVE';
export const REQUEST_FOR_LEAVE = 'REQUEST_FOR_LEAVE';
// export const REQUEST_FOR_REVISE_LEAVE = 'REQUEST_FOR_REVISE_LEAVE';
export const CHANGE_LEAVE_STATUS = 'CHANGE_LEAVE_STATUS';
export const GET_LEAVES_COUNT = 'GET_LEAVES_COUNT';
export const CLEAR_LEAVES_COUNT = 'CLEAR_LEAVES_COUNT';
export const CLEAR_LEAVES = 'CLEAR_LEAVES';
export const GET_LEAVES_TO_DISABLE = 'GET_LEAVES_TO_DISABLE';
export const CLEAR_LEAVES_TO_DISABLE = 'CLEAR_LEAVES_TO_DISABLE';

export const getAllLeaves =
  (values = {}) =>
  async (dispatch, getState) => {
    const queryParams = {};
    if (values.leave_type) queryParams.reason = values.leave_type;
    if (values.company) queryParams.company = values.company;
    if (values.status) queryParams.is_approved = values.status;
    if (values.user_profile_id)
      queryParams.user_profile_id = values.user_profile_id;
    if (values.start_date)
      queryParams.from_date = moment(values.start_date).format('YYYY-MM-DD');
    if (values.end_date)
      queryParams.to_date = moment(values.end_date).format('YYYY-MM-DD');

    try {
      const res = await axios.get(`${API_URL}/api/leave/`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({
        type: GET_LEAVES,
        payload: res.data.results,
      });
      if (res.data && res.data.length === 0) {
        dispatch(returnErrors({ message: 'No leaves found!' }));
      }
    } catch (err) {
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getLeaves = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/leave/leave_allist/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_LEAVES,
      payload: res.data,
    });
    if (res.data && res.data.length === 0) {
      dispatch(returnErrors({ message: 'No leaves found!' }));
    }
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getUserLeaves = userId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/LeaveDetail/leave_history_list/?user_profile_id=${userId}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_USER_LEAVES,
      payload: res.data,
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
};

export const getUserLeavesNumbers = userId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/LeavesCount/remaining_leaves_check/?user_profile_id=${userId}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_USER_LEAVES_NUMBERS,
      payload: res.data,
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
};

export const getFilteredLeaves =
  (values = {}) =>
  async (dispatch, getState) => {
    const queryParams = {};
    if (values.leave_type) queryParams.reason = values.leave_type;
    if (values.status) queryParams.is_approved = values.status;
    if (values.user_profile_id)
      queryParams.user_profile_id = values.user_profile_id;
    if (values.start_date)
      queryParams.from_date = moment(values.start_date).format('YYYY-MM-DD');
    if (values.end_date)
      queryParams.to_date = moment(values.end_date).format('YYYY-MM-DD');
    try {
      const res = await axios.get(`${API_URL}/api/leave/leave_allist/`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({
        type: GET_FILTERED_LEAVES,
        payload: res.data,
      });
      if (res.data && res.data.length === 0) {
        dispatch(returnErrors({ message: 'No leaves found!' }));
      }
    } catch (err) {
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const deleteLeave = id => async (dispatch, getState) => {
  try {
    await axios.delete(`${API_URL}/api/leave/${id}/`, tokenConfig(getState));
    dispatch(createMessage({ message: 'Leave Deleted' }));
    dispatch({ type: DELETE_LEAVE, payload: id });
  } catch (err) {
    throw err;
  }
};

export const requestForLeave = values => async (dispatch, getState) => {
  const data = {
    leave_from_date: moment(values.start_date).format('YYYY-MM-DD'),
    leave_to_date: moment(values.end_date).format('YYYY-MM-DD'),
    reason: values.reason,
    user_profile_id_id: values.user_profile_id,
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/leave/`,
      data,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Successfully requested for leave' }));
    dispatch({
      type: REQUEST_FOR_LEAVE,
      payload: res.data,
    });
  } catch (err) {
    throw err;
  }
};

export const requestReviseLeave = values => async (dispatch, getState) => {
  const daysDifference = moment(values.start_date).diff(moment(), 'days');
  const data = {
    previous_leave: values.id,
    leave_from_date: moment(values.start_date).format('YYYY-MM-DD'),
    leave_to_date: moment(values.end_date).format('YYYY-MM-DD'),
    reason: values.reason,
    user_profile_id_id: values.user_profile_id,
    is_future_date: daysDifference > 0,
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/leave/revised`,
      data,
      tokenConfig(getState)
    );

    dispatch(createMessage({ message: 'Successfully revised for leave' }));
    dispatch({
      type: REQUEST_FOR_LEAVE,
      payload: res.data,
    });
  } catch (err) {
    throw err;
  }
};

export const changeLeaveStatus =
  (leaveId, reason, remarks, status) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/LeaveDetail/leave_approval/?id=${leaveId}&leave_status=${status}&remarks=${remarks}&reason=${reason}`,
        tokenConfig(getState)
      );
      dispatch({
        type: CHANGE_LEAVE_STATUS,
        payload: res.data,
      });

      if (status === 'approved') {
        dispatch(createMessage({ message: 'Leave Approved Successfully' }));
      }

      if (status === 'denied') {
        dispatch(createMessage({ message: 'Leave Denied Successfully' }));
      }
    } catch (err) {
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getLeavesCount = userId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/LeavesCount/remaining_leaves_check/?user_profile_id=${userId}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_LEAVES_COUNT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getLeavesToDisable = userId => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/LeaveDetail/total_leaves_for_calendar/?user_profile_id=${userId}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_LEAVES_TO_DISABLE,
      payload: res.data.my_profile_leaves_calender,
    });
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const clearLeavesCount = () => ({
  type: CLEAR_LEAVES_COUNT,
});

export const clearLeaves = () => ({
  type: CLEAR_LEAVES,
});

export const clearLeavesToDisable = () => ({
  type: CLEAR_LEAVES_TO_DISABLE,
});

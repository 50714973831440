/* eslint-disable no-useless-escape */

// API BASE URL
export const API_URL = 'https://login.washland.ae';

// export const STORAGE_URL = 'https://d1ws2nmm1yk911.cloudfront.net/media/';
export const SOCKET_URL = 'wss://stagingcrm.beyonderissolutions.com';
export const STORAGE_URL =
  'https://crm-application-storages.s3.amazonaws.com/media_dolce_jou';

export const ROWS_PER_PAGE = '20';

export const EMAIL_REGEX =
  /^(([^`~!$%^&*?/|}{#=<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// /^[^<>()[\]\\,;:\%#^\s@\"$&!@\*\+]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

// HR ROLES
export const HR_CONTROLLER = 'hr_controller';
export const HR_MANAGER = 'hr_manager';
export const HR_STAFF = 'hr_staff';

// ACCOUNTING ROLES
export const ACCOUNTING_CONTROLLER = 'accounting_controller';
export const ACCOUNTING_MANAGER = 'accounting_manager';
export const ACCOUNTING_STAFF = 'accounting_staff';
export const ACCOUNTING_STAFF_RECEIVABLE = 'accounting_staff_receivable';
export const ACCOUNTING_STAFF_PAYABLE = 'accounting_staff_payable';

// OPERATIONS ROLES
export const OPERATION_CONTROLLER = 'operations_controller';
export const OPERATION_MANAGER = 'operations_manager';

// SUPER USER
export const SUPER_USER = 'superuser';

// luxury explorer main company

export const LUXURY_COMPANY = 'Luxury Events and VIP Travel DMCC';
export const DEFAULT_PAGINATION_DATA = {
  count: 0,
  results: [],
  previous: null,
  next: null,
};

export const IMAGE_PLACEHOLDER = '/img/image-placeholder.png';

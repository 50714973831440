import axios from 'axios';
import { createMessage, returnErrors } from './messagesActions';
import { capitalizeFirstLetter } from '../../utils/helpers';
import {
  API_URL,
  ACCOUNTING_STAFF_PAYABLE,
  ACCOUNTING_STAFF_RECEIVABLE,
  ACCOUNTING_CONTROLLER,
  ACCOUNTING_MANAGER,
} from '../../utils/constants';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const UPDATE_USER = 'UPDATE_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const GLOBAL_STATE = 'GLOBAL_STATE';

// Setup config with token - helper function
export const tokenConfig = getState => {
  // Get toke from state
  const { token } = getState().auth;

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
};
// GET COMPANY DETAILS
export const getCompanyDetails = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/company/info`,
      tokenConfig(getState)
    );
    const data = {
      ...res.data,
      company_name: capitalizeFirstLetter(res.data.company_name),
    };
    dispatch({
      type: GLOBAL_STATE,
      payload: data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(
        returnErrors({ message: ['An unexpected error occurred!'] }, 500)
      );
  }
};

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch, getState) => {
  // User Loading
  dispatch({ type: USER_LOADING });

  try {
    const res = await axios.get(
      `${API_URL}/api/auth/user`,
      tokenConfig(getState)
    );

    const modifiedData = {
      ...res.data,
      isStaffPayable: res.data.groups[0].name === ACCOUNTING_STAFF_PAYABLE,
      isStaffReceivable:
        res.data.groups[0].name === ACCOUNTING_STAFF_RECEIVABLE,
      isAccountingStaff:
        res.data.groups[0].name === ACCOUNTING_STAFF_PAYABLE ||
        res.data.groups[0].name === ACCOUNTING_STAFF_RECEIVABLE,
      isAccountingSupervisor:
        res.data.groups[0].name === ACCOUNTING_CONTROLLER ||
        res.data.groups[0].name === ACCOUNTING_MANAGER,
    };
    dispatch({
      type: USER_LOADED,
      payload: modifiedData,
    });
    if (res.status === 200) dispatch(getCompanyDetails());
  } catch (err) {
    // dispatch(returnErrors(err.response.data, err.response.status));
    dispatch({ type: AUTH_ERROR });
  }
};

// LOGIN USER
export const login = values => async dispatch => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Request Body
  const { email, password } = values;
  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(`${API_URL}/api/auth/login`, body, config);
    const modifiedData = {
      ...res.data,
      user: {
        ...res.data.user,
        isStaffPayable:
          res.data.user.groups[0].name === ACCOUNTING_STAFF_PAYABLE,
        isStaffReceivable:
          res.data.user.groups[0].name === ACCOUNTING_STAFF_RECEIVABLE,
        isAccountingStaff:
          res.data.user.groups[0].name === ACCOUNTING_STAFF_PAYABLE ||
          res.data.user.groups[0].name === ACCOUNTING_STAFF_RECEIVABLE,
        isAccountingSupervisor:
          res.data.user.groups[0].name === ACCOUNTING_CONTROLLER ||
          res.data.user.groups[0].name === ACCOUNTING_MANAGER,
      },
    };

    dispatch({
      type: LOGIN_SUCCESS,
      payload: modifiedData,
    });
    if (res.status === 200) dispatch(getCompanyDetails());
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(
        returnErrors({ message: ['An unexpected error occurred!'] }, 500)
      );
    dispatch({ type: LOGIN_FAIL });
  }
};

// LOGOUT USER
export const logout = () => async (dispatch, getState) => {
  try {
    await axios.post(`${API_URL}/api/auth/logout`, null, tokenConfig(getState));
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(
        returnErrors({ message: ['An unexpected error occurred!'] }, 500)
      );
  }
};

// CHANGE PASSWORD
export const changeUserPassword = values => async (dispatch, getState) => {
  const body = values;
  let responseStatus = '';
  try {
    const res = await axios.patch(
      `${API_URL}/api/auth/pasword`,
      body,
      tokenConfig(getState)
    );

    responseStatus = res.status;
  } catch (err) {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
      responseStatus = err.response.status;
    } else
      dispatch(
        returnErrors({ message: ['An unexpected error occurred!'] }, 500)
      );
  }
  return responseStatus;
};

// RESET PASSWORD

export const resetPasswordEmail = values => async (dispatch, getState) => {
  // Request Body
  const { email } = values;
  const body = JSON.stringify({ email });
  let responseStatus = '';
  try {
    const res = await axios.post(
      `${API_URL}/api/auth/forgotpassword`,
      body,
      tokenConfig(getState)
    );
    // dispatch(createMessage({ message: 'Password Link Sent to Email' }));
    responseStatus = res.status;
  } catch (err) {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
      responseStatus = err.response.status;
    } else
      dispatch(
        returnErrors({ message: ['An unexpected error occurred!'] }, 500)
      );
  }
  return responseStatus;
};

// CHANGE PASSWORD
export const changePassword = values => async (dispatch, getState) => {
  // Request Body
  const { password } = values;
  const body = JSON.stringify({ password });

  try {
    await axios.patch(
      `${API_URL}/api/auth/changePassword`,
      body,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Password Updated' }));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(
        returnErrors({ message: ['An unexpected error occurred!'] }, 500)
      );
  }
};

import axios from 'axios';
import moment from 'moment';
import round from 'lodash/round';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { addActivityLog } from './salesAccountsActions';
import groupArrayOfObjects from '../../utils/groupArrayOfObjects';
import formatAmount from '../../utils/formatAmount';

export const GET_PRO_INVOICES_LIST = 'GET_PRO_INVOICES_LIST';
export const GET_PRO_INVOICE = 'GET_PRO_INVOICE';
export const CLEAR_PRO_INVOICE = 'CLEAR_PRO_INVOICE';
export const GET_PRO_INVOICES = 'GET_PRO_INVOICES';
export const ADD_PRO_INVOICE = 'ADD_PRO_INVOICE';
export const DELETE_PRO_INVOICE = 'DELETE_PRO_INVOICE';
export const CANCEL_PRO_INVOICE = 'CANCEL_PRO_INVOICE';
export const EDIT_PRO_INVOICE = 'EDIT_PRO_INVOICE';
export const GET_PRO_INVOICE_DOCS = 'GET_PRO_INVOICE_DOCS';
export const ADD_PRO_INVOICE_DOCS = 'ADD_PRO_INVOICE_DOCS';
export const DELETE_PRO_INVOICE_DOC = 'DELETE_PRO_INVOICE_DOC';
export const GET_PRO_INVOICE_NOTE = 'GET_PRO_INVOICE_NOTE';
export const EDIT_PRO_INVOICE_NOTE = 'EDIT_PRO_INVOICE_NOTE';
export const DELETE_PRO_INVOICE_NOTE = 'DELETE_PRO_INVOICE_NOTE';
export const PRO_INVOICE_LOADING = 'PRO_INVOICE_LOADING';
export const PRO_INVOICE_LOADED = 'PRO_INVOICE_LOADED';

// GET PRO INVOICE
export const getProInvoice = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/proInvoices/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_PRO_INVOICE, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR PRO INVOICE
export const clearProInvoice = () => {
  return { type: CLEAR_PRO_INVOICE };
};

// GET PRO INVOICES LIST
export const getProInvoicesList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: PRO_INVOICE_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/list/proInvoices`,
      { ...tokenConfig(getState), params }
    );
    dispatch({ type: GET_PRO_INVOICES_LIST, payload: res.data });
    dispatch({ type: PRO_INVOICE_LOADED });
  } catch (err) {
    dispatch({ type: PRO_INVOICE_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET PRO INVOICES
export const getProInvoices = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/proInvoices/`,
      { ...tokenConfig(getState), params }
    );
    dispatch({ type: GET_PRO_INVOICES, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET LATEST PRO INVOICE
export const getLatestProInvoice = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/proInvoices/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// GET PROFORMA INVOICE DOCS
export const getProInvoiceDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/proInvoices/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_PRO_INVOICE_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// UPLOAD PROFORMA INVOICES FILE
export const uploadProInvoicesFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/sales/proInvoices/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_PRO_INVOICE_DOCS, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE PROFORMA INVOICE DOC
export const deleteProInvoiceDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/proInvoices/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Proforma Invoice Doc Deleted' }));
    dispatch({ type: DELETE_PRO_INVOICE_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET PRO INVOICE NOTE
export const getProInvoiceNote = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/proInvoices/notes/${id}/`,
      tokenConfig(getState)
    );
    // dispatch({ type: GET_PRO_INVOICE_NOTE, payload: res.data });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// DELETE PRO INVOICE NOTE
export const deleteProInvoiceNote = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/proInvoices/notes/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Proforma Invoice Note Deleted' }));
    dispatch({ type: DELETE_PRO_INVOICE_NOTE, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE PRO INVOICE
export const deleteProInvoice = proInvoice => async (dispatch, getState) => {
  const newActivityLog = {
    customer_id: proInvoice.sales_account_id,
    activity_type: 'Proforma Invoice',
    activity_title: 'Proforma Invoice Deleted',
    module_num: proInvoice.pro_invoice_num,
    amount: round(proInvoice.without_change_grand_total, 2),
    description: `Proforma Invoice ${
      proInvoice.pro_invoice_formatted_number
    } of amount ${proInvoice.currency_symbol}${formatAmount(
      proInvoice.without_change_grand_total
    )} deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/proInvoices/${proInvoice.id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Proforma Invoice Deleted' }));
    dispatch({ type: DELETE_PRO_INVOICE, payload: proInvoice.id });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// ADD PRO INVOICE
export const addProInvoice = values => async (dispatch, getState) => {
  const modifiedproInvoiceItems = [];
  const proInvoiceItems = values.pro_invoice_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    currency: item.currency,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: round(item.vat_rate, 2),
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
    cost_price: round(item.cost_price, 2),
    cost_amount_ex_vat: round(item.cost_amount_ex_vat, 2),
    cost_vat_rate: item.cost_vat_rate,
    cost_vat_amount: round(item.cost_vat_amount, 2),
    total_cost: round(item.total_cost, 2),
    profit: round(item.profit, 2),
  }));

  Object.values(groupArrayOfObjects(proInvoiceItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedproInvoiceItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );
  const proInvoiceNotes = [
    {
      note_type: 'create',
      notes: values.notes,
    },
  ];
  const proInvoice = {
    quotation: values.quotation,
    sales_company_id: values.sales_company_id,
    sales_account_id: values.sales_account_id,
    pro_invoice_prefix: values.pro_invoice_prefix,
    currency: values.currency,
    convert_to_aed: values.convert_to_aed,
    exchange_rate_of_proinvoice_currency: round(values.exchange_rate, 6),
    type: 'pro_invoice',
    pro_invoice_date:
      values.pro_invoice_date && values.pro_invoice_date !== ''
        ? moment(values.pro_invoice_date).format('YYYY-MM-DD')
        : null,
    expiry_date:
      values.expiry_date && values.expiry_date !== ''
        ? moment(values.expiry_date).format('YYYY-MM-DD')
        : null,
    payment_terms: values.payment_terms,
    prepared_by: values.prepared_by,
    location: values.location,
    period_start:
      values.period_start && values.period_start !== ''
        ? moment(values.period_start).format('YYYY-MM-DD')
        : null,
    period_end:
      values.period_end && values.period_end !== ''
        ? moment(values.period_end).format('YYYY-MM-DD')
        : null,
    event: values.event,
    event_sorting: values.event ? values.event.toLowerCase() : '',
    terms: values.terms,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    amount_total_aed: round(values.amount_total_aed, 2),
    vat_total_aed: round(values.vat_total_aed, 2),
    grand_total_aed: round(values.grand_total_aed, 2),
    sales_person_id: values.sales_person ? values.sales_person : null,
    customer_type: values.customer_type,
    tax_treatment: values.tax_treatment,
    tax_reg_num: values.tax_reg_num,
    place_of_supply: values.place_of_supply,
    phone_num: values.phone_num,
    fax_num: values.fax_num,
    email: values.email,
    status: values.status,
    bank_account_id: values.bank_account_id,
    account_num: values.account_num,
    iban: values.iban,
    address: values.address,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
    country: values.country,
    pro_invoice_items: modifiedproInvoiceItems,
    pro_invoice_notes: values.notes ? proInvoiceNotes : [],
    show_stamp: values.show_stamp,
    stamp: values.stamp,
    is_operational: values.is_operational,
    // show_emp_logo: values.show_emp_logo,
    pro_invoice_name: values.pro_invoice_name,
    signature: values.signature,
  };

  // for operational pro-invoice
  if (values.rfp) proInvoice.rfp = values.rfp;
  if (values.parent) proInvoice.parent = values.parent;

  try {
    const { data } = await axios.post(
      `${API_URL}/api/accounting/sales/proInvoices/`,
      proInvoice,
      tokenConfig(getState)
    );

    const newActivityLog = {
      module_id: data.id,
      customer_id: data.sales_account_id,
      activity_type: 'Proforma Invoice',
      activity_title: 'Proforma Invoice Added',
      module_num: data.pro_invoice_num,
      amount: round(data.without_change_grand_total, 2),
      description: `Proforma Invoice ${
        data.pro_invoice_formatted_number
      } of amount ${data.currency_symbol}${formatAmount(
        data.without_change_grand_total
      )} created`,
    };

    dispatch(createMessage({ message: 'Proforma Invoice Added' }));
    dispatch({ type: ADD_PRO_INVOICE, payload: data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT PRO INVOICE NOTE
export const editProInvoiceNote = values => async (dispatch, getState) => {
  const proInvoiceNote = {
    pro_invoice_id: values.pro_invoice_id,
    note_type: 'update',
    notes: values.notes,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/sales/proInvoices/notes/${values.id}/`,
      proInvoiceNote,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Proforma Invoice Note Updated' }));
    dispatch({ type: EDIT_PRO_INVOICE_NOTE, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};
// CANCEL PRO INVOICE
export const cancelProInvoice = id => async (dispatch, getState) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/api/accounting/sales/proinvoices/${id}/statusUpdate?status=Cancelled`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Proforma Invoice Cancelled' }));
    dispatch({ type: CANCEL_PRO_INVOICE, payload: data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
  // }
};

// EDIT PRO INVOICE
export const editProInvoice = values => async (dispatch, getState) => {
  const modifiedproInvoiceItems = [];
  const proInvoiceItems = values.pro_invoice_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    currency: item.currency,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: round(item.vat_rate, 2),
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
    cost_price: round(item.cost_price, 2),
    cost_amount_ex_vat: round(item.cost_amount_ex_vat, 2),
    cost_vat_rate: item.cost_vat_rate,
    cost_vat_amount: round(item.cost_vat_amount, 2),
    total_cost: round(item.total_cost, 2),
    profit: round(item.profit, 2),
  }));

  Object.values(groupArrayOfObjects(proInvoiceItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedproInvoiceItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );

  const proInvoiceNotes = [
    {
      pro_invoice_id: values.id,
      note_type: 'update',
      notes: values.notes,
    },
  ];
  const proInvoice = {
    quotation: values.quotation,
    sales_company_id: values.sales_company_id,
    sales_account_id: values.sales_account_id,
    pro_invoice_prefix: values.pro_invoice_prefix,
    currency: values.currency,
    convert_to_aed: values.convert_to_aed,
    exchange_rate_of_proinvoice_currency: round(values.exchange_rate, 6),
    type: 'pro_invoice',
    pro_invoice_date:
      values.pro_invoice_date && values.pro_invoice_date !== ''
        ? moment(values.pro_invoice_date).format('YYYY-MM-DD')
        : null,
    expiry_date:
      values.expiry_date && values.expiry_date !== ''
        ? moment(values.expiry_date).format('YYYY-MM-DD')
        : null,
    payment_terms: values.payment_terms,
    prepared_by: values.prepared_by,
    location: values.location,
    period_start:
      values.period_start && values.period_start !== ''
        ? moment(values.period_start).format('YYYY-MM-DD')
        : null,
    period_end:
      values.period_end && values.period_end !== ''
        ? moment(values.period_end).format('YYYY-MM-DD')
        : null,
    event: values.event,
    event_sorting: values.event ? values.event.toLowerCase() : '',
    terms: values.terms,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    amount_total_aed: round(values.amount_total_aed, 2),
    vat_total_aed: round(values.vat_total_aed, 2),
    grand_total_aed: round(values.grand_total_aed, 2),
    sales_person_id: values.sales_person ? values.sales_person : null,
    customer_type: values.customer_type,
    tax_treatment: values.tax_treatment,
    tax_reg_num: values.tax_reg_num,
    place_of_supply: values.place_of_supply,
    phone_num: values.phone_num,
    fax_num: values.fax_num,
    email: values.email,
    bank_account_id: values.bank_account_id,
    account_num: values.account_num,
    iban: values.iban,
    address: values.address,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
    country: values.country,
    pro_invoice_items: modifiedproInvoiceItems,
    pro_invoice_notes: values.notes ? proInvoiceNotes : [],
    show_stamp: values.show_stamp,
    stamp: values.stamp,
    is_operational: values.is_operational,
    // show_emp_logo: values.show_emp_logo,
    pro_invoice_name: values.pro_invoice_name,
    signature: values.signature,
    operation_status: 'Edited',
    status: 'draft', // Status will be changed to draft when edited
  };

  try {
    const { data } = await axios.put(
      `${API_URL}/api/accounting/sales/proInvoices/${values.id}/`,
      proInvoice,
      tokenConfig(getState)
    );

    const newActivityLog = {
      module_id: data.id,
      customer_id: data.sales_account_id,
      activity_type: 'Proforma Invoice',
      activity_title: 'Proforma Invoice Updated',
      module_num: data.pro_invoice_num,
      amount: round(data.without_change_grand_total, 2),
      description: `Proforma Invoice ${
        data.pro_invoice_formatted_number
      } of amount ${data.currency_symbol}${formatAmount(
        data.without_change_grand_total
      )} updated`,
    };

    dispatch(createMessage({ message: 'Proforma Invoice Updated' }));
    dispatch({ type: EDIT_PRO_INVOICE, payload: data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

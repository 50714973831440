/* eslint-disable react/jsx-indent */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import AccountingSidebar from './AccountantSidebar';
import SingleSubCategory from './SingleSubCategory';
import {
  ACCOUNTING_CONTROLLER,
  ACCOUNTING_STAFF,
  OPERATION_CONTROLLER,
  OPERATION_MANAGER,
  SUPER_USER,
  HR_STAFF,
} from '../../../utils/constants';

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    department: PropTypes.string,
    // allowSystemAccess: PropTypes.bool.isRequired,
    group: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    department: null,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { department, group, onClick, user } = this.props;
    const isSuperUser = group === SUPER_USER;

    const isHrStaff = group === HR_STAFF;
    const operationalRole = user && user.groups && user.groups[0].name;
    const showLeavesTab =
      operationalRole === OPERATION_CONTROLLER ||
      operationalRole === OPERATION_MANAGER;
    const isAccountingDept =
      user.isAccountingSupervisor || user.isAccountingStaff;
    const allowSystemAccess = user && user.profile.system_access;
    return (
      <div className="sidebar__content">
        {allowSystemAccess ? (
          <>
            {isAccountingDept ? (
              <>
                {isAccountingDept && (
                  <AccountingSidebar handleClick={onClick} />
                )}
              </>
            ) : (
              <React.Fragment>
                <ul className="sidebar__block">
                  <SidebarLink
                    title="Dashboard"
                    icon="tachometer-alt"
                    route="/"
                    onClick={this.hideSidebar}
                  />
                </ul>
                <ul className="sidebar__block">
                  {(isSuperUser || department === 'finance') && (
                    <SidebarCategory title="Accounting" icon="calculator">
                      <SidebarLink
                        title="Items"
                        route="/pages/accounting/items"
                        onClick={this.hideSidebar}
                      />
                      <SidebarLink
                        title="Inventory Adjustments"
                        route="/pages/accounting/inventoryAdjustments"
                        onClick={this.hideSidebar}
                      />
                      <SidebarLink
                        title="Banking"
                        route="/pages/accounting/banking"
                        onClick={this.hideSidebar}
                      />
                      <SidebarCategory title="Sales">
                        <SidebarLink
                          title="Accounts"
                          route="/pages/accounting/sales/accounts"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Customers"
                          route="/pages/accounting/sales/customers"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Quotations"
                          route="/pages/accounting/sales/quotations"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Proforma Invoices"
                          route="/pages/accounting/sales/proInvoices"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Invoices"
                          route="/pages/accounting/sales/invoices"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Payments Received"
                          route="/pages/accounting/sales/paymentsReceived"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Credit Notes"
                          route="/pages/accounting/sales/creditNotes"
                          onClick={this.hideSidebar}
                        />
                      </SidebarCategory>
                      <SidebarCategory title="Purchases">
                        <SidebarLink
                          title="Suppliers"
                          route="/pages/accounting/purchases/suppliers"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Expenses"
                          route="/pages/accounting/purchases/expenses"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Purchase Orders"
                          route="/pages/accounting/purchases/purOrders"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Bills"
                          route="/pages/accounting/purchases/bills"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Payments Made"
                          route="/pages/accounting/purchases/paymentsMade"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Debit Notes"
                          route="/pages/accounting/purchases/supplierCredits"
                          onClick={this.hideSidebar}
                        />
                      </SidebarCategory>
                      <SidebarCategory title="Accountant">
                        <SidebarLink
                          title="Currencies"
                          route="/pages/accounting/accountant/currencies"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Manual Journals"
                          route="/pages/accounting/accountant/journals"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Tax Payments"
                          route="/pages/accounting/accountant/taxPayments"
                          onClick={this.hideSidebar}
                        />
                        {/* <SidebarLink
                  title="Tax Adjustments"
                  route="/pages/accounting/accountant/taxAdjustments"
                  onClick={this.hideSidebar}
                /> */}
                        {/* <SidebarLink
                  title="Currency Adjustments"
                  route="/pages/accounting/accountant/currencyAdjustments"
                  onClick={this.hideSidebar}
                /> */}
                        <SidebarLink
                          title="Chart of Accounts"
                          route="/pages/accounting/accountant/chartOfAccounts"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Transaction Locking"
                          route="/pages/accounting/accountant/transactionLocking"
                          onClick={this.hideSidebar}
                        />
                      </SidebarCategory>
                    </SidebarCategory>
                  )}
                  {(isSuperUser || department === 'hr') && (
                    <SidebarLink
                      title="Activities"
                      route="/pages/manageActivities"
                      onClick={this.hideSidebar}
                      icon="clipboard-list"
                    />
                  )}
                  {(isSuperUser || department === 'hr') && (
                    <SidebarCategory title="HR" icon="file-alt">
                      <SidebarCategory title="Company">
                        <SidebarLink
                          title="Company Profiles"
                          route="/pages/hr/companyProfiles"
                          onClick={this.hideSidebar}
                        />
                        <SidebarLink
                          title="Company Files"
                          route="/pages/hr/companyFiles"
                          onClick={this.hideSidebar}
                        />
                      </SidebarCategory>
                      <SidebarLink
                        title="Holidays"
                        route="/pages/hr/companyHolidays"
                        onClick={this.hideSidebar}
                      />
                      <SidebarLink
                        title="Employees"
                        route="/pages/hr/employees"
                        onClick={this.hideSidebar}
                      />
                      {!isHrStaff && (
                        <SidebarLink
                          title="Leaves Request"
                          route="/pages/hr/leaves"
                          onClick={this.hideSidebar}
                        />
                      )}
                      <SidebarLink
                        title="Documents Request"
                        route="/pages/hr/employeeRequests"
                        onClick={this.hideSidebar}
                      />
                      <SidebarLink
                        title="Salaries"
                        route="/pages/hr/salaries"
                        onClick={this.hideSidebar}
                      />
                    </SidebarCategory>
                  )}
                  {department === 'finance' && group !== ACCOUNTING_STAFF && (
                    <SidebarCategory title="HR" icon="diamond">
                      <SidebarLink
                        title="Salaries"
                        route="/pages/hr/salaries"
                        onClick={this.hideSidebar}
                      />
                      <SidebarLink
                        title="Leaves"
                        route="/pages/hr/leaves"
                        onClick={this.hideSidebar}
                        hidden={group !== ACCOUNTING_CONTROLLER}
                      />
                    </SidebarCategory>
                  )}
                  <SidebarLink
                    title="Internal Meetings"
                    route="/pages/manageInternalMeetings"
                    onClick={this.hideSidebar}
                    icon="handshake"
                  />
                  {(isSuperUser || department === 'finance') && (
                    <SidebarLink
                      title="Reports"
                      route="/pages/reports"
                      icon="project-diagram"
                      onClick={this.hideSidebar}
                    />
                  )}
                  {(isSuperUser || department === 'finance') && (
                    <SidebarLink
                      title="Settings"
                      route="/pages/settings"
                      onClick={this.hideSidebar}
                      icon="cog"
                    />
                  )}
                  <SidebarLink
                    icon="database"
                    title="Storage"
                    route="/pages/storage/main"
                    onClick={this.hideSidebar}
                  />
                  <SidebarLink
                    icon="trash-alt"
                    title="Trash"
                    route="/pages/storage/trash"
                    onClick={this.hideSidebar}
                  />
                </ul>
              </React.Fragment>
            )}
          </>
        ) : (
          <React.Fragment>
            <ul className="sidebar__block">
              <SidebarLink
                title="My Profile"
                icon="user"
                route="/pages/user/profile"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Internal Meetings"
                icon="handshake"
                route="/pages/manageInternalMeetings"
                onClick={this.hideSidebar}
              />
              {showLeavesTab && (
                <SingleSubCategory
                  title="Miscellaneous"
                  icon="fas fa-layer-group"
                >
                  <SidebarLink
                    title="Employee Leaves"
                    route="/pages/hr/leaves"
                    onClick={this.hideSidebar}
                  />
                </SingleSubCategory>
              )}
            </ul>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  department: state.auth.user.profile ? state.auth.user.profile.department : '',
  allowSystemAccess: state.auth.user.profile
    ? state.auth.user.profile.system_access
    : false,
  group: state.auth.user.groups.length > 0 && state.auth.user.groups[0].name,
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(SidebarContent);

import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
// import TopbarNotification from './TopbarNotification';
import SearchBar from '../../Common/SearchBar';
import QuickCreateMenu from './QuickCreateMenu';
import RecentActivity from './RecentActivity';
import TopbarSettings from './TopbarSettings';

function Topbar(props) {
  const { pathname } = useLocation();
  const { changeMobileSidebarVisibility, changeSidebarVisibility } = props;
  const {
    profile: { department },
    companyInfo = {},
  } = useSelector(state => ({
    profile: state.auth.user.profile,
    companyInfo: state.auth.companyDetail,
  }));

  const isLogoUrlEmpty = isEmpty(companyInfo.company_logo);

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link
            style={{ backgroundImage: isLogoUrlEmpty ? '' : 'none' }}
            className="topbar__logo"
            to="/"
          >
            {!isLogoUrlEmpty && (
              <img className="wh-100" src={companyInfo.company_logo} alt="" />
            )}
          </Link>
        </div>
        {/* allowSystemAccess && */}
        {department === 'finance' && (
          <>
            {!pathname.includes('/pages/reports/') && (
              <div className="topbar__middle">
                <div className="d-flex align-items-center">
                  <QuickCreateMenu />
                  <RecentActivity />
                </div>
                <SearchBar />
              </div>
            )}
          </>
        )}
        <div className="topbar__right">
          {/* <TopbarNotification /> */}
          {/* allowSystemAccess && */}
          {(department === 'finance' || department === 'hr') && (
            <div className="d-flex align-items-center justify-content-between">
              <TopbarSettings department={department} />
            </div>
          )}
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
}

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;

import {
  GET_BILL,
  CLEAR_BILL,
  GET_BILLS,
  DELETE_BILL,
  VOID_BILL,
  ADD_BILL,
  EDIT_BILL,
  ADD_BILL_DOC,
  GET_BILL_DOCS,
  DELETE_BILL_DOC,
  MARK_BILL_OPEN,
  GET_NON_DRAFT_BILLS,
  GET_BILL_JOURNAL,
  CLEAR_BILL_JOURNAL,
  GET_BILL_PAYMENTS,
  DELETE_BILL_CREDIT_APPLIED_PAYMENT,
  CLEAR_BILL_PAYMENTS,
  BILLS_LOADING,
  BILLS_LOADED,
  EDIT_BILL_NOTE,
  DELETE_BILL_NOTE,
  GET_BILLS_LIST,
} from '../actions/billsActions';

const initialState = {
  count: null,
  billsList: [],
  bills: [],
  nonDraftBills: [],
  docs: [],
  fetched: null,
  journal: null,
  billPayments: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BILL:
      return {
        ...state,
        fetched: action.payload,
      };
    case VOID_BILL:
      return {
        ...state,
        fetched: action.payload,
      };

    case CLEAR_BILL:
      return {
        ...state,
        fetched: null,
      };
    case GET_BILLS_LIST:
      return {
        ...state,
        count: action.payload.count,
        billsList: action.payload.results,
      };
    case GET_BILLS:
      return {
        ...state,
        count: action.payload.count,
        bills: action.payload.results,
      };
    case DELETE_BILL:
      return {
        ...state,
        billsList: state.billsList.filter(bill => bill.id !== action.payload),
      };
    case DELETE_BILL_CREDIT_APPLIED_PAYMENT:
      return {
        ...state,
        billPayments: {
          ...state.billPayments,
          credits_applied: state.billPayments.credits_applied.filter(
            item => item.id !== action.payload
          ),
        },
      };
    case ADD_BILL:
      return {
        ...state,
        billsList: [...state.billsList],
      };
    case EDIT_BILL:
      return {
        ...state,
      };
    case GET_BILL_DOCS:
      return {
        ...state,
        docs: action.payload,
      };
    case ADD_BILL_DOC:
      return {
        ...state,
        docs: [...state.docs, action.payload],
      };
    case DELETE_BILL_DOC:
      return {
        ...state,
        docs: state.docs.filter(doc => doc.id !== action.payload),
      };
    case MARK_BILL_OPEN:
      return {
        ...state,
        fetched: action.payload,
      };
    case GET_NON_DRAFT_BILLS:
      return {
        ...state,
        nonDraftBills: action.payload,
      };
    case GET_BILL_JOURNAL:
      return {
        ...state,
        journal: action.payload,
      };
    case CLEAR_BILL_JOURNAL:
      return {
        ...state,
        journal: null,
      };
    case GET_BILL_PAYMENTS:
      return {
        ...state,
        billPayments: action.payload,
      };
    case CLEAR_BILL_PAYMENTS:
      return {
        ...state,
        billPayments: null,
      };
    case BILLS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case BILLS_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_BILL_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            bill_notes: state.fetched.bill_notes.map(note => {
              if (note.id === action.payload.id) return action.payload;
              return note;
            }),
          },
        },
      };
    case DELETE_BILL_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            bill_notes: state.fetched.bill_notes.filter(
              note => note.id !== action.payload
            ),
          },
        },
      };
    default:
      return state;
  }
};

import {
  GET_BANK_ACCOUNT,
  CLEAR_BANK_ACCOUNT,
  GET_BANK_ACCOUNTS,
  DELETE_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT,
  GET_BANK_ACCOUNT_BY_CURRENCY,
  BANK_ACCOUNT_LOADING,
  BANK_ACCOUNT_LOADED,
  CHANGE_BANK_STATUS,
  BANK_STATEMENT_HEADERS,
  ADD_BANK_STATEMENT,
  GET_BANK_STATEMENTS,
  UPDATE_BANK_STATEMENT,
} from '../actions/bankingActions';

const initialState = {
  bankAccounts: [],
  bankStatementHeaders: [],
  bankStatements: [],
  fetched: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_ACCOUNT:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_BANK_ACCOUNT:
      return {
        ...state,
        fetched: null,
      };
    case GET_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: action.payload,
      };
    case DELETE_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: state.bankAccounts.filter(
          account => account.id !== action.payload
        ),
      };
    case ADD_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: [...state.bankAccounts, action.payload],
      };
    case EDIT_BANK_ACCOUNT:
      return {
        ...state,
      };
    case GET_BANK_ACCOUNT_BY_CURRENCY:
      return {
        ...state,
        fetched: action.payload,
      };
    case BANK_ACCOUNT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case BANK_ACCOUNT_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case CHANGE_BANK_STATUS:
      return {
        ...state,
        bankAccounts: state.bankAccounts.map(item =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case BANK_STATEMENT_HEADERS:
      return {
        ...state,
        bankStatementHeaders: action.payload,
      };
    case ADD_BANK_STATEMENT:
      return {
        ...state,
        bankStatements: [...state.bankStatements, ...action.payload],
      };
    case GET_BANK_STATEMENTS:
      return {
        ...state,
        bankStatements: action.payload,
      };
    case UPDATE_BANK_STATEMENT:
      return {
        ...state,
        bankStatements: state.bankStatements.filter(
          item => item.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

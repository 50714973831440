/* eslint react/forbid-prop-types: 0 */
/* eslint no-script-url: 0 */

import React, { useState } from 'react';
import CogOutlineIcon from 'mdi-react/CogOutlineIcon';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import TopbarMenuLink from './TopbarMenuLink';
import ToolTip from '../../Common/ToolTip';

function TopbarSettings(props) {
  const [isOpen, setOpen] = useState(false);
  const { department } = props;
  const history = useHistory();
  const isHrDepart = department === 'hr';
  const toggle = () => {
    setOpen(prevState => !prevState);
    if (isHrDepart) history.push('/pages/settings');
  };
  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggle}>
        <ToolTip text="Settings" position="bottom" className="my-auto">
          <CogOutlineIcon size={20} color="#BABBBC" />
        </ToolTip>
      </button>
      {isOpen && (
        <button className="topbar__back" type="button" onClick={toggle} />
      )}
      {!isHrDepart && (
        <Collapse isOpen={isOpen} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink
            title="General Settings"
            path="/pages/settings"
            onClick={toggle}
          /> */}
            <TopbarMenuLink
              title="Opening Balance"
              path="/pages/accounting/openingBalance"
              onClick={toggle}
            />
            <TopbarMenuLink
              title="Stamp and Signature"
              path="/pages/accounting/stampAndSignature"
              onClick={toggle}
            />
            <TopbarMenuLink
              title="Requester Signature"
              path="/pages/accounting/uploadSignature"
              onClick={toggle}
            />
          </div>
        </Collapse>
      )}
    </div>
  );
}
TopbarSettings.propTypes = {
  department: PropTypes.string.isRequired,
};
export default TopbarSettings;

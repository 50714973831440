import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Layout from '../Layout/index';

function WrappedRoutes({ Pages, Dashboard }) {
  const { isLoading } = useSelector(state => state.ui);

  return (
    <div>
      <Layout />
      {isLoading && (
        <div className={`load${isLoading ? '' : ' loaded'}`}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path
                fill="#0097b2"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      )}
      <div className="container__wrap">
        <Switch>
          <Route path="/pages" component={Pages} />
          <Route exact path="/" component={Dashboard} />
        </Switch>
      </div>
    </div>
  );
}

WrappedRoutes.propTypes = {
  Pages: PropTypes.func.isRequired, // element
  Dashboard: PropTypes.object.isRequired,
};

export default WrappedRoutes;

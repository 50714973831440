/* eslint-disable max-len */
import axios from 'axios';
import moment from 'moment';
import round from 'lodash/round';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { addActivityLog } from './salesAccountsActions';
import formatAmount from '../../utils/formatAmount';
import groupArrayOfObjects from '../../utils/groupArrayOfObjects';

export const GET_INVOICES_LIST = 'GET_INVOICES_LIST';
export const GET_INVOICE = 'GET_INVOICE';
export const CLEAR_INVOICE = 'CLEAR_INVOICE';
export const GET_INVOICES = 'GET_INVOICES';
export const ADD_INVOICE = 'ADD_INVOICE';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const EDIT_INVOICE = 'EDIT_INVOICE';
export const VOID_INVOICE = 'VOID_INVOICE';
export const GET_INVOICE_NOTE = 'GET_INVOICE_NOTE';
export const EDIT_INVOICE_NOTE = 'EDIT_INVOICE_NOTE';
export const DELETE_INVOICE_NOTE = 'DELETE_INVOICE_NOTE';
export const GET_INVOICE_DOCS = 'GET_INVOICE_DOCS';
export const ADD_INVOICE_DOCS = 'ADD_INVOICE_DOCS';
export const DELETE_INVOICE_DOC = 'DELETE_INVOICE_DOC';
export const MARK_UNPAID = 'MARK_UNPAID';
export const GET_INVOICE_JOURNAL = 'GET_INVOICE_JOURNAL';
export const CLEAR_INVOICE_JOURNAL = 'CLEAR_INVOICE_JOURNAL';
export const GET_INVOICE_PAYMENTS = 'GET_INVOICE_PAYMENTS';
export const DELETE_CREDIT_APPLIED_PAYMENT = 'DELETE_CREDIT_APPLIED_PAYMENT';
export const CLEAR_INVOICE_PAYMENTS = 'CLEAR_INVOICE_PAYMENTS';
export const INVOICE_LOADING = 'INVOICE_LOADING';
export const INVOICE_LOADED = 'INVOICE_LOADED';

// GET INVOICE
export const getInvoice = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/invoices/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_INVOICE, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR INVOICE
export const clearInvoice = () => ({ type: CLEAR_INVOICE });

// GET INVOICES LIST
export const getInvoicesList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: INVOICE_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/list/invoices`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_INVOICES_LIST, payload: res.data });
    dispatch({ type: INVOICE_LOADED });
  } catch (err) {
    dispatch({ type: INVOICE_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET INVOICES
export const getInvoices = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/accounting/sales/invoices/`, {
      ...tokenConfig(getState),
      params,
    });
    dispatch({ type: GET_INVOICES, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET LATEST INVOICE
export const getLatestInvoice = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/invoices/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// GET INVOICE NOTE
export const getInvoiceNote = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/invoices/notes/${id}/`,
      tokenConfig(getState)
    );
    // dispatch({ type: GET_INVOICE_NOTE, payload: res.data });
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// DELETE INVOICE NOTE
export const deleteInvoiceNote = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/invoices/notes/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Invoice Note Deleted' }));
    dispatch({ type: DELETE_INVOICE_NOTE, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE INVOICE
export const deleteInvoice = invoice => async (dispatch, getState) => {
  const newActivityLog = {
    customer_id: invoice.sales_account_id,
    activity_type: 'Invoice',
    activity_title: 'Invoice Deleted',
    module_num: invoice.invoice_num,
    amount: round(invoice.without_change_grand_total, 2),
    description: `Invoice ${invoice.invoice_formatted_number} of amount ${
      invoice.currency_symbol
    }${formatAmount(invoice.without_change_grand_total)} deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/invoices/${invoice.id}/`,
      {
        ...tokenConfig(getState),
        data: {
          type: 'invoice',
          invoice_date: invoice.invoice_date,
        },
      }
    );
    dispatch(createMessage({ message: 'Invoice Deleted' }));
    dispatch({ type: DELETE_INVOICE, payload: invoice.id });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD INVOICE
export const addInvoice = values => async (dispatch, getState) => {
  const modifiedInvoiceItems = [];
  const invoiceItems = values.invoice_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    // supplier: item.supplier,
    currency: item.currency,
    // payment_mode: item.payment_mode,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: item.vat_rate,
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
    cost_price: round(item.cost_price, 2),
    cost_amount_ex_vat: round(item.cost_amount_ex_vat, 2),
    cost_vat_rate: item.cost_vat_rate,
    cost_vat_amount: round(item.cost_vat_amount, 2),
    total_cost: round(item.total_cost, 2),
    profit: round(item.profit, 2),
  }));

  Object.values(groupArrayOfObjects(invoiceItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedInvoiceItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );
  const invoiceNotes = [
    {
      note_type: 'create',
      notes: values.notes,
    },
  ];
  const invoice = {
    quotation: values.quotation,
    pro_invoice: values.pro_invoice,
    sales_company_id: values.sales_company_id,
    sales_account_id: values.sales_account_id,
    // invoice_num: values.invoice_num,
    invoice_prefix: values.invoice_prefix,
    currency: values.currency,
    convert_to_aed: values.convert_to_aed,
    exchange_rate_of_invoice_currency: round(values.exchange_rate, 6),
    invoice_date:
      values.invoice_date && values.invoice_date !== ''
        ? moment(values.invoice_date).format('YYYY-MM-DD')
        : null,
    expiry_date:
      values.expiry_date && values.expiry_date !== ''
        ? moment(values.expiry_date).format('YYYY-MM-DD')
        : null,
    payment_terms: values.payment_terms,
    prepared_by: values.prepared_by,
    location: values.location,
    period_start:
      values.period_start && values.period_start !== ''
        ? moment(values.period_start).format('YYYY-MM-DD')
        : null,
    period_end:
      values.period_end && values.period_end !== ''
        ? moment(values.period_end).format('YYYY-MM-DD')
        : null,
    event: values.event,
    invoice_name: values.invoice_name,
    event_sorting: values.event ? values.event.toLowerCase() : '',
    terms: values.terms,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    amount_total_aed: round(values.amount_total_aed, 2),
    vat_total_aed: round(values.vat_total_aed, 2),
    grand_total_aed: round(values.grand_total_aed, 2),
    sales_person_id: values.sales_person ? values.sales_person : null,
    lpo_num: values.lpo_num,
    lpo_date:
      values.lpo_date && values.lpo_date !== ''
        ? moment(values.lpo_date).format('YYYY-MM-DD')
        : null,
    customer_type: values.customer_type,
    tax_treatment: values.tax_treatment,
    tax_reg_num: values.tax_reg_num,
    place_of_supply: values.place_of_supply,
    phone_num: values.phone_num,
    fax_num: values.fax_num,
    email: values.email,
    bank_account_id: values.bank_account_id,
    account_num: values.account_num,
    iban: values.iban,
    address: values.address,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
    country: values.country,
    invoice_items: modifiedInvoiceItems,
    invoice_notes: values.notes ? invoiceNotes : [],
    aed_amount: 0.0,
    type: 'invoice',
    other_amount: round(values.other_amount, 2),
    receipt_notes: values.receipt_notes,
    show_stamp: values.show_stamp,
    stamp: values.stamp,
    is_operational: values.is_operational,
    // show_emp_logo: values.show_emp_logo,
    signature: values.signature,
  };

  if (values.status) invoice.status = values.status;

  // for operational invoice
  if (values.rfp) invoice.rfp = values.rfp;

  try {
    const { data } = await axios.post(
      `${API_URL}/api/accounting/sales/invoices/`,
      invoice,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Invoice Added' }));

    const newActivityLog = {
      module_id: data.id,
      customer_id: data.sales_account_id,
      activity_type: 'Invoice',
      activity_title: 'Invoice Added',
      module_num: data.invoice_num,
      amount: round(data.without_change_grand_total, 2),
      description: `Invoice ${data.invoice_formatted_number} of amount ${
        data.currency_symbol
      }${formatAmount(data.without_change_grand_total)} created`,
    };

    dispatch({ type: ADD_INVOICE, payload: data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT INVOICE NOTE
export const editInvoiceNote = values => async (dispatch, getState) => {
  const invoiceNote = {
    invoice_id: values.invoice_id,
    note_type: 'update',
    notes: values.notes,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/sales/invoices/notes/${values.id}/`,
      invoiceNote,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Invoice Note Updated' }));
    dispatch({ type: EDIT_INVOICE_NOTE, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};
export const voidInvoice = values => async (dispatch, getState) => {
  const {
    invoiceId,
    saleAccount,
    invoiceNum,
    withoutChangeGrandTotal,
    invoiceFormattedNumber,
    currencySymbol,
    reason,
  } = values;
  const newActivityLog = {
    module_id: invoiceId,
    customer_id: saleAccount,
    activity_type: 'Invoice',
    activity_title: 'Invoice Updated',
    module_num: invoiceNum,
    amount: round(withoutChangeGrandTotal, 2),
    description: `Invoice ${invoiceFormattedNumber} of amount ${currencySymbol} ${formatAmount(
      withoutChangeGrandTotal
    )} status updated to Void`,
  };
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/invoices/${invoiceId}/status?reason=${reason}`,
      // invoiceNote,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Invoice Updated to Void' }));
    dispatch({ type: VOID_INVOICE, payload: res.data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT INVOICE
export const editInvoice = values => async (dispatch, getState) => {
  const modifiedInvoiceItems = [];
  const invoiceItems = values.invoice_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    // supplier: item.supplier,
    currency: item.currency,
    // payment_mode: item.payment_mode,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: item.vat_rate,
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
    cost_price: round(item.cost_price, 2),
    cost_amount_ex_vat: round(item.cost_amount_ex_vat, 2),
    cost_vat_rate: item.cost_vat_rate,
    cost_vat_amount: round(item.cost_vat_amount, 2),
    total_cost: round(item.total_cost, 2),
    profit: round(item.profit, 2),
  }));

  Object.values(groupArrayOfObjects(invoiceItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedInvoiceItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );

  const invoiceNotes = [
    {
      note_type: 'update',
      notes: values.notes,
    },
  ];
  const invoice = {
    quotation: values.quotation,
    pro_invoice: values.pro_invoice,
    sales_company_id: values.sales_company_id,
    sales_account_id: values.sales_account_id,
    // invoice_num: values.invoice_num,
    invoice_prefix: values.invoice_prefix,
    currency: values.currency,
    convert_to_aed: values.convert_to_aed,
    exchange_rate_of_invoice_currency: round(values.exchange_rate, 6),
    invoice_date:
      values.invoice_date && values.invoice_date !== ''
        ? moment(values.invoice_date).format('YYYY-MM-DD')
        : null,
    expiry_date:
      values.expiry_date && values.expiry_date !== ''
        ? moment(values.expiry_date).format('YYYY-MM-DD')
        : null,
    payment_terms: values.payment_terms,
    prepared_by: values.prepared_by,
    location: values.location,
    period_start:
      values.period_start && values.period_start !== ''
        ? moment(values.period_start).format('YYYY-MM-DD')
        : null,
    period_end:
      values.period_end && values.period_end !== ''
        ? moment(values.period_end).format('YYYY-MM-DD')
        : null,
    event: values.event,
    invoice_name: values.invoice_name,
    event_sorting: values.event ? values.event.toLowerCase() : '',
    terms: values.terms,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    amount_total_aed: round(values.amount_total_aed, 2),
    vat_total_aed: round(values.vat_total_aed, 2),
    grand_total_aed: round(values.grand_total_aed, 2),
    sales_person_id: values.sales_person ? values.sales_person : null,
    lpo_num: values.lpo_num,
    lpo_date:
      values.lpo_date && values.lpo_date !== ''
        ? moment(values.lpo_date).format('YYYY-MM-DD')
        : null,
    customer_type: values.customer_type,
    tax_treatment: values.tax_treatment,
    tax_reg_num: values.tax_reg_num,
    place_of_supply: values.place_of_supply,
    phone_num: values.phone_num,
    fax_num: values.fax_num,
    email: values.email,
    bank_account_id: values.bank_account_id,
    account_num: values.account_num,
    iban: values.iban,
    address: values.address,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
    country: values.country,
    invoice_items: modifiedInvoiceItems,
    invoice_notes: values.notes ? invoiceNotes : [],
    aed_amount: 0.0,
    type: 'invoice',
    other_amount: round(values.other_amount, 2),
    reason: values.reason,
    status: values.status,
    receipt_notes: values.receipt_notes,
    show_stamp: values.show_stamp,
    stamp: values.stamp,
    is_operational: values.is_operational,
    // show_emp_logo: values.show_emp_logo,
    signature: values.signature,
  };

  try {
    const { data } = await axios.put(
      `${API_URL}/api/accounting/sales/invoices/${values.id}/`,
      invoice,
      tokenConfig(getState)
    );

    const newActivityLog = {
      module_id: data.id,
      customer_id: data.sales_account_id,
      activity_type: 'Invoice',
      activity_title: 'Invoice Updated',
      module_num: data.invoice_num,
      amount: round(data.without_change_grand_total, 2),
      description: `Invoice ${data.invoice_formatted_number} of amount ${
        data.currency_symbol
      }${formatAmount(data.without_change_grand_total)} updated`,
    };

    dispatch(createMessage({ message: 'Invoice Updated' }));
    dispatch({ type: EDIT_INVOICE, payload: data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// UPLOAD INVOICES FILE
export const uploadInvoicesFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/sales/invoices/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_INVOICE_DOCS, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// GET INVOICES DOCS
export const getInvoiceDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/invoices/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_INVOICE_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE INVOICE DOC
export const deleteInvoiceDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/invoices/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Invoice Doc Deleted' }));
    dispatch({ type: DELETE_INVOICE_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// MARK INVOICE AS UNPAID
export const markInvoiceAsUnpaid = invoice => async (dispatch, getState) => {
  const newActivityLog = {
    module_id: invoice.id,
    customer_id: invoice.sales_account_id,
    activity_type: 'Invoice',
    activity_title: 'Invoice Updated',
    module_num: invoice.invoice_num_only_number,
    amount: round(invoice.grand_total_in_numeric, 2),
    description: `Invoice "${invoice.invoice_num}" status changed from draft to open`,
  };

  const formData = new FormData();
  formData.append(
    'invoice_file',
    invoice.invoice_file,
    invoice.invoice_file.name
  );

  try {
    const res = await axios.patch(
      `${API_URL}/api/accounting/sales/invoices/${invoice.id}/save`,
      formData,
      tokenConfig(getState)
    );
    dispatch({
      type: MARK_UNPAID,
      payload: res.data,
    });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// GET INVOICE JOURNAL
export const getInvoiceJournal = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/invoices/${id}/journals`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_INVOICE_JOURNAL,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR INVOICE JOURNAL
export const clearInvoiceJournal = () => ({ type: CLEAR_INVOICE_JOURNAL });

// GET INVOICE PAYMENTS
export const getInvoicePayments = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/invoices/${id}/payment`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_INVOICE_PAYMENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
export const deleteCreditAppliedPayment =
  (invoiceId, id) => async (dispatch, getState) => {
    try {
      await axios.delete(
        `${API_URL}/api/accounting/sales/invoices/${invoiceId}/payment?delete=${id}`,
        tokenConfig(getState)
      );

      dispatch({
        type: DELETE_CREDIT_APPLIED_PAYMENT,
        payload: id,
      });
      dispatch(
        createMessage({ message: 'Credit Applied deleted successfully' })
      );
      dispatch(getInvoice(invoiceId));
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// CLEAR INVOICE JOURNAL
export const clearInvoicePayments = () => ({ type: CLEAR_INVOICE_PAYMENTS });

import axios from 'axios';
import moment from 'moment';
import round from 'lodash/round';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { addSupplierActivityLog } from './suppliersActions';

export const GET_EXPENSES_LIST = 'GET_EXPENSES_LIST';
export const GET_EXPENSES = 'GET_EXPENSES';
export const GET_EXPENSE = 'GET_EXPENSE';
export const CLEAR_EXPENSE = 'CLEAR_EXPENSE';
export const ADD_EXPENSE = 'ADD_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const EDIT_EXPENSE = 'EDIT_EXPENSE';
export const ADD_EXPENSE_DOCS = 'ADD_EXPENSE_DOCS';
export const DELETE_EXPENSE_DOC = 'DELETE_EXPENSE_DOC';
export const GET_EXPENSE_JOURNAL = 'GET_EXPENSE_JOURNAL';
export const CLEAR_EXPENSE_JOURNAL = 'CLEAR_EXPENSE_JOURNAL';
export const EXPENSE_LOADING = 'EXPENSE_LOADING';
export const EXPENSE_LOADED = 'EXPENSE_LOADED';

// GET Expense
export const getExpense = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/expenses/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_EXPENSE,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR Expense
export const clearExpense = () => {
  return {
    type: CLEAR_EXPENSE,
  };
};

// GET Expenses List
export const getExpensesList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: EXPENSE_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/list/expenses`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({
      type: GET_EXPENSES_LIST,
      payload: res.data,
    });
    dispatch({ type: EXPENSE_LOADED });
  } catch (err) {
    dispatch({ type: EXPENSE_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET Expenses
export const getExpenses = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/expenses/`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({
      type: GET_EXPENSES,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ACTIVE Expenses
export const getActiveExpenses = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/expenses/active`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_EXPENSES,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE Expense
export const deleteExpense = expense => async (dispatch, getState) => {
  const newActivityLog = {
    supplier_id: expense.supplier_id,
    activity_type: 'Expense',
    activity_title: 'Expense Deleted',
    module_num: expense.id,
    amount: round(expense.total, 2),
    description: `Expense of amount ${expense.currency}${round(
      expense.total,
      2
    )} deleted`,
  };
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/expenses/${expense.id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Expense Deleted' }));
    dispatch({
      type: DELETE_EXPENSE,
      payload: expense.id,
    });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// ADD Expense
export const addExpense = values => async (dispatch, getState) => {
  const tax = (values.total_without_tax * values.tax_rate_perc) / 100;
  const expense = {
    ...values,
    vat_total: round(tax, 2),
    total: round(values.total_without_tax + tax, 2),
    type: 'expense', // added type && supplier_date key for transection locking
    expense_date: moment(values.expense_date).format('YYYY-MM-DD'),
  };

  if (!expense.tax_rate_id) delete expense.tax_rate_id;
  if (!expense.tax_rate) delete expense.tax_rate;
  if (!expense.supplier_id) delete expense.supplier_id;
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/expenses/`,
      expense,
      tokenConfig(getState)
    );
    const newActivityLog = {
      module_id: res.data.id,
      supplier_id: expense.supplier_id,
      module_name: res.data.expense_account.account_name,
      activity_type: 'Expense',
      activity_title: 'Expense Added',
      module_num: res.data.id,
      amount: round(expense.grand_total, 2),
      description: `Expense of amount ${expense.currency}${round(
        expense.total,
        2
      )} created`,
    };
    dispatch(createMessage({ message: 'Expense Added' }));
    dispatch({
      type: ADD_EXPENSE,
      payload: res.data,
    });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT Expense
export const editExpense = values => async (dispatch, getState) => {
  const tax = (values.total_without_tax * values.tax_rate_perc) / 100;
  const expense = {
    ...values,

    vat_total: round(tax, 2),
    total: round(values.total_without_tax + tax, 2),
    type: 'expense', // added type  key for transection locking
    expense_date: moment(values.expense_date).format('YYYY-MM-DD'),
  };

  if (!expense.tax_rate_id) delete expense.tax_rate_id;
  if (!expense.tax_rate) delete expense.tax_rate;
  if (!expense.supplier_id) delete expense.supplier_id;

  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/purchases/expenses/${values.id}/`,
      expense,
      tokenConfig(getState)
    );
    const newActivityLog = {
      module_id: expense.id,
      supplier_id: expense.supplier_id,
      module_name: res.data.expense_account.account_name,
      activity_type: 'Expense',
      activity_title: 'Expense Updated',
      module_num: expense.id,
      amount: round(expense.total, 2),
      description: 'Expense details updated',
    };
    dispatch(createMessage({ message: 'Expense Updated' }));
    dispatch({
      type: EDIT_EXPENSE,
      payload: res.data,
    });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// UPLOAD Expense FILE
export const uploadExpenseFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/expenses/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_EXPENSE_DOCS, payload: res.data });
    dispatch(getExpense(values.id));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// GET Expense Journal
export const getExpenseJournal = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchase/expense/${id}/journals`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_EXPENSE_JOURNAL,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Delete Expense Doc
export const deleteExpenseDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/expenses/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: DELETE_EXPENSE_DOC,
      payload: id,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR Expense Journal
export const clearExpenseJournal = () => ({ type: CLEAR_EXPENSE_JOURNAL });

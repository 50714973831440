import axios from 'axios';
import moment from 'moment';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { addActivityLog } from './miscActions';
import { createMessage, returnErrors } from './messagesActions';
import getEmailTemplate from '../../utils/getEmailTemplate';

export const GET_SUPPLIERS_LIST = 'GET_SUPPLIERS_LIST';
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const CLEAR_SUPPLIER = 'CLEAR_SUPPLIER';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const EDIT_SUPPLIER = 'EDIT_SUPPLIER';
export const GET_UNPAID_BILLS = 'GET_UNPAID_BILLS';
export const CLEAR_BILLS = 'CLEAR_BILLS';
export const GET_SUPPLIER_INCOME = 'GET_SUPPLIER_INCOME';
export const GET_SUPPLIER_TRANSACTIONS = 'GET_SUPPLIER_TRANSACTIONS';
export const ADD_SUPPLIER_COMMENT = 'ADD_SUPPLIER_COMMENT';
export const DELETE_SUPPLIER_COMMENT = 'DELETE_SUPPLIER_COMMENT';
export const GET_SUPPLIER_COMMENTS = 'GET_SUPPLIER_COMMENTS';
export const CLEAR_SUPPLIER_COMMENTS = 'CLEAR_SUPPLIER_COMMENTS';
export const GET_SUPPLIER_ACTIVITY_LOGS = 'GET_SUPPLIER_ACTIVITY_LOGS';
export const CLEAR_SUPPLIER_ACTIVITY_LOG = 'CLEAR_SUPPLIER_ACTIVITY_LOG';
export const GET_SUPPLIER_STATEMENT = 'GET_SUPPLIER_STATEMENT';
export const SUPPLIERS_LOADING = 'SUPPLIERS_LOADING';
export const SUPPLIERS_LOADED = 'SUPPLIERS_LOADED';
export const GET_SUPPLIER_CREDIT_DETAILS = 'GET_SUPPLIER_CREDIT_DETAILS';
export const CHECK_SUPPLIER_CREDIT_LIMIT = 'CHECK_SUPPLIER_CREDIT_LIMIT';
export const GET_SUPPLIER_CONTACTS = 'GET_SUPPLIER_CONTACTS';
export const ADD_SUPPLIER_DOC = 'ADD_SUPPLIER_DOC';
export const GET_SUPPLIER_DOCS = 'GET_SUPPLIER_DOCS';
export const DELETE_SUPPLIER_DOC = 'DELETE_SUPPLIER_DOC';
export const GET_SUPPLIER_EMAILS = 'GET_SUPPLIER_EMAILS';

// GET ACTIVITY LOG
export const getActivityLogsBySupplierId = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchase/supplier/${id}/activityLog`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_SUPPLIER_ACTIVITY_LOGS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD ACTIVITY LOG
export const addSupplierActivityLog = values => async (dispatch, getState) => {
  try {
    await axios.post(
      `${API_URL}/api/accounting/purchase/supplier/SupplierActivityLog/`,
      values,
      tokenConfig(getState)
    );
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
};

export const getLatestSupplierNum = () => async (_, getState) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/purchases/suppliers/latest`,
    tokenConfig(getState)
  );
  return data;
};

// GET SUPPLIER
export const getSupplier = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/suppliers/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SUPPLIER,
      payload: res.data,
    });
    dispatch({
      type: GET_SUPPLIER_CONTACTS,
      payload: res.data.supplier_contacts,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR SUPPLIER
export const clearSupplier = () => {
  return {
    type: CLEAR_SUPPLIER,
  };
};

// GET SUPPLIERS LIST
export const getSuppliersList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPLIERS_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/list/suppliers`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({
      type: GET_SUPPLIERS_LIST,
      payload: res.data,
    });
    dispatch({ type: SUPPLIERS_LOADED });
  } catch (err) {
    dispatch({ type: SUPPLIERS_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET SUPPLIERS
export const getSuppliers = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/suppliers/`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({
      type: GET_SUPPLIERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET ACTIVE SUPPLIERS
export const getActiveSuppliers = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/suppliers/active`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SUPPLIERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE SUPPLIER
export const deleteSupplier = supplier => async (dispatch, getState) => {
  const deleteActivityLog = {
    activity_type: 'Supplier',
    activity_title: 'Supplier Deleted',
    description: `${supplier.supplier_name} deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/suppliers/${supplier.id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Supplier Deleted' }));
    dispatch({
      type: DELETE_SUPPLIER,
      payload: supplier.id,
    });
    dispatch(addActivityLog(deleteActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD SUPPLIER
export const addSupplier = values => async (dispatch, getState) => {
  const supplierDetails = {
    ...values,
    type: 'supplier',
    supplier_date: moment(new Date()).format('YYYY-MM-DD'), // added type && supplier_date key for transection locking
    opening_balance_date: values.opening_balance_date
      ? moment(values.opening_balance_date).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD'),
    limit: values.limit || 0,
    supplier_name_sorting: values.supplier_name
      ? values.supplier_name.toLowerCase()
      : '',
  };
  const supplierContacts = supplierDetails.supplier_contacts.map(item => ({
    title: item.title,
    first_name: item.first_name,
    last_name: item.last_name,
    phone_num: item.phone_num,
    mobile_num: item.mobile_num,
    email: item.email,
  }));

  const primaryContactDetails = supplierDetails.primary_contact_details;
  if (!Object.values(primaryContactDetails).every(item => !item)) {
    primaryContactDetails.primary_contact = true;
    supplierContacts.push(primaryContactDetails);
  }
  delete supplierDetails.primary_contact_details;

  const supplier = {
    ...supplierDetails,
    supplier_contacts: supplierContacts,
  };

  if (!supplier.account_number) delete supplier.account_number;

  try {
    const lastNum = await dispatch(getLatestSupplierNum());
    supplier.transaction_num = lastNum.latest_num ? lastNum.latest_num + 1 : 1;

    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/suppliers/`,
      supplier,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Supplier Added' }));
    dispatch({
      type: ADD_SUPPLIER,
      payload: res.data,
    });
    const newActivityLog = {
      module_id: res.data.id,
      module_num: res.data.id,
      activity_type: 'Supplier',
      activity_title: 'Supplier Added',
      description: `${supplier.supplier_name} created`,
    };
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT SUPPLIER
export const editSupplier = values => async (dispatch, getState) => {
  const supplierDetails = {
    ...values,
    type: 'supplier',
    supplier_date: moment(new Date()).format('YYYY-MM-DD'), // added type && supplier_date key for transection locking
    opening_balance_date: values.opening_balance_date
      ? moment(values.opening_balance_date).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD'),
    limit: values.limit || 0,
    supplier_name_sorting: values.supplier_name
      ? values.supplier_name.toLowerCase()
      : '',
  };
  const supplierContacts = [...supplierDetails.supplier_contacts];

  if (
    !Object.values(supplierDetails.primary_contact_details).every(item => !item)
  ) {
    const primaryContact = {
      title: supplierDetails.primary_contact_details.title,
      first_name: supplierDetails.primary_contact_details.first_name,
      last_name: supplierDetails.primary_contact_details.last_name,
      email: supplierDetails.primary_contact_details.email,
      mobile_num: supplierDetails.primary_contact_details.mobile_num,
      phone_num: supplierDetails.primary_contact_details.phone_num,
      primary_contact: true,
    };

    supplierContacts.push(primaryContact);
  }
  delete supplierDetails.primary_contact_details;

  const supplier = {
    ...supplierDetails,
    supplier_contacts: supplierContacts,
  };

  if (!supplier.account_number) delete supplier.account_number;

  const updateActivityLog = {
    module_id: values.id,
    module_num: values.id,
    activity_type: 'Supplier',
    activity_title: 'Supplier Updated',
    description: `${supplier.supplier_name} updated`,
  };

  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/purchases/suppliers/${values.id}/`,
      supplier,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Supplier Updated' }));
    dispatch({
      type: EDIT_SUPPLIER,
      payload: res.data,
    });
    dispatch(addActivityLog(updateActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET UNPAID BILLS
export const getUnpaidBills = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/suppliers/${id}/unpaidBills`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_UNPAID_BILLS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR BILLS
export const clearSupplierBills = () => {
  return {
    type: CLEAR_BILLS,
  };
};

// GET SUPPLIER INCOME
export const getSupplierIncome =
  (id, duration = 'this fiscal year', type = 'accrual') =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/purchase/supplier/${id}/income`,
        {
          ...tokenConfig(getState),
          params: {
            duration,
            type,
          },
        }
      );
      dispatch({ type: GET_SUPPLIER_INCOME, payload: res.data });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET SUPPLIER TRANSACTIONS
export const getSupplierTransactions = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchase/supplier/${id}/transaction`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_SUPPLIER_TRANSACTIONS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD SUPPLIER COMMENTS
export const addSupplierComment = values => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/purchase/SupplierComments/`,
      values,
      tokenConfig(getState)
    );
    dispatch({ type: ADD_SUPPLIER_COMMENT, payload: res.data });
    dispatch(createMessage({ message: 'Comment Added' }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET SUPPLIER COMMENTS
export const getSupplierComments = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchase/supplier/${id}/comments`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_SUPPLIER_COMMENTS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE SUPPLIER COMMENTS
export const deleteSupplierComment = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchase/SupplierComments/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: DELETE_SUPPLIER_COMMENT, payload: id });
    dispatch(createMessage({ message: 'Comment Deleted' }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR ACTIVITY LOG
export const clearSupplierActivityLog = () => ({
  type: CLEAR_SUPPLIER_ACTIVITY_LOG,
});

// GET SUPPLIER STATEMENT
export const getSupplierStatement =
  (id, duration, type, customDates) => async (dispatch, getState) => {
    const queryParams = {
      duration,
      filter_type: type,
    };

    if (customDates) {
      queryParams.custom_start_date = moment(customDates.start_date).format(
        'YYYY-MM-DD'
      );
      queryParams.custom_end_date = moment(customDates.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/purchase/supplier/${id}/statement`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_SUPPLIER_STATEMENT, payload: res.data });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET SUPPLIER CREDIT DETAILS
export const getSupplierCreditDetails = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/suppliers/${id}/paymentsVoucher`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_SUPPLIER_CREDIT_DETAILS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET SUPPLIER LIMIT
export const checkSupplierCreditLimit =
  (id, amount) => async (dispatch, getState) => {
    try {
      await axios.get(
        `${API_URL}/api/SupplierLimitCheck?supplier_id=${id}&total_amount=${amount}`,
        tokenConfig(getState)
      );
      dispatch({ type: CHECK_SUPPLIER_CREDIT_LIMIT, payload: false });
    } catch (err) {
      if (err.response && err.response.status === 400) {
        dispatch({ type: CHECK_SUPPLIER_CREDIT_LIMIT, payload: true });
      } else {
        dispatch(returnErrors(err.response.data, err.response.status));
      }
    }
  };

// UPLOAD SUPPLIER FILE
export const uploadSupplierFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/supplier/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_SUPPLIER_DOC, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// GET SUPPLIER DOCS
export const getSupplierDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/supplier/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SUPPLIER_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE SUPPLIER DOC
export const deleteSupplierDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/supplier/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Supplier Doc Deleted' }));
    dispatch({ type: DELETE_SUPPLIER_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET SUPPLIER EMAILS
export const getSupplierEmails = id => async (dispatch, getState) => {
  const params = {
    account_id: id,
    category: 'supplier',
  };

  try {
    const res = await axios.get(`${API_URL}/api/sendEmail/`, {
      ...tokenConfig(getState),
      params,
    });
    dispatch({ type: GET_SUPPLIER_EMAILS, payload: res.data.results });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// UPLOAD EMAIL DOCUMENT
export const uploadEmailFile = async (document, config) => {
  const documentData = new FormData();
  documentData.append('file', document);

  const res = await axios.post(
    `${API_URL}/api/sendEmailFile/`,
    documentData,
    config
  );
  return res.data;
};

// SEND EMAIL
export const sendEmail = (id, data) => async (dispatch, getState) => {
  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';

  const toEmails = data.sendTo.map(item => ({ email: item }));
  const ccEmails = data.cc.map(item => ({ email: item }));

  const payload = {
    sender: data.fromEmail,
    category: 'supplier',
    account_id: id,
    type: data.type,
    to: toEmails,
    cc: ccEmails,
    subject: data.subject,
    body: data.message,
    files: [],
    template: getEmailTemplate(data.templateData),
  };

  try {
    if (data.statement) {
      const statement = await uploadEmailFile(data.statement, config);
      payload.files.push(statement);
    }
    if (data.document) {
      const document = await uploadEmailFile(data.document, config);
      payload.files.push(document);
    }
    await axios.post(
      `${API_URL}/api/sendEmail/`,
      payload,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Email Sent Successfully' }));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    else
      dispatch(returnErrors({ message: ['An unexpected error occured'] }, 500));
  }
};

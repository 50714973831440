import axios from 'axios';
import store from '../containers/App/store';
import { API_URL } from '../utils/constants';
import { capitalizeFirstLetter } from '../utils/helpers';

export const getConfig = () => {
  // Get token from state
  const { getState } = store;
  const { token } = getState().auth;
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
};

export const getOperationsConfig = () => {
  // Get token from state
  const { getState } = store;
  const { token } = getState().auth;
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers.Authorization = `CRM ${token}`;
  }

  return config;
};

export const markRequestAsSeen = async id => {
  await axios.get(
    `${API_URL}/api/empevents/rfp_seen/${id}/status`,
    getConfig()
  );
};

export const getCompanyProfiles = async () => {
  const { data } = await axios.get(
    `${API_URL}/api/CompanyProfile/`,
    getConfig()
  );

  return data;
};

export const getRFPRequests = async status => {
  const { data } = await axios.get(`${API_URL}/api/empevents/rfp/`, {
    ...getConfig(),
    params: {
      status,
    },
  });

  return data;
};

export const getRFPDetails = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/rfp/${id}/`,
    getConfig()
  );

  return data;
};

export const updateRFPStatus = async ({ id, ...payload }) => {
  const { data } = await axios.put(
    `${API_URL}/api/empevents/rfp_status/${id}/`,
    payload,
    getConfig()
  );

  return data;
};

export const getRFPQuotations = async (rfpID, filters) => {
  const { data } = await axios.get(`${API_URL}/api/empevents/rfp_quotations/`, {
    ...getConfig(),
    params: {
      rfp_id: rfpID,
      ...filters,
    },
  });

  return data.results;
};

export const getSingleQuotation = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/sales/quotations/${id}/`,
    getConfig()
  );

  return data;
};

export const sendRFPQuotation = async (quotationID, quotationFile) => {
  const formData = new FormData();
  formData.append('quotation_file', quotationFile, quotationFile.name);

  const config = getConfig();
  config.headers['content-type'] = 'multipart/form-data';

  const { data } = await axios.patch(
    `${API_URL}/api/accounting/sales/quotation/${quotationID}/upload/send`,
    formData,
    config
  );

  return data;
};

export const deleteMultipleRFPQuotation = async selectedQuotations => {
  const targetQuotationIDs = selectedQuotations.map(item => item.id);

  // Sending static id '1' to satisfy backend requirements
  const { data } = await axios.delete(
    `${API_URL}/api/accounting/multiple/quotations/delete/1/?quotation_ids=${targetQuotationIDs.join(
      ','
    )}`,
    getConfig()
  );

  return data;
};

export const getSiteInspections = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/rfp_site_inspection/`,
    {
      ...getConfig(),
      params: {
        rfp_id: id,
      },
    }
  );

  return data;
};

export const updateSiteInspection = async ({ id, ...payload }) => {
  const { data } = await axios.put(
    `${API_URL}/api/empevents/rfp_site_inspection/${id}/`,
    payload,
    getConfig()
  );

  return data;
};

export const rescheduleSiteInspection = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp_site_inspection/reschedule/`,
    payload,
    getConfig()
  );

  return data;
};

export const getEventRoles = async id => {
  const { data } = await axios.get(`${API_URL}/api/empevents/rfp_roles/`, {
    ...getConfig(),
    params: {
      rfp_id: id,
    },
  });

  return data;
};

export const getBudgets = async id => {
  const { data } = await axios.get(`${API_URL}/api/empevents/rfp_budget/`, {
    ...getConfig(),
    params: {
      rfp_id: id,
    },
  });

  return data.results;
};

export const getBudgetDetails = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/rfp_budget/${id}/`,
    getConfig()
  );

  return data;
};

export const getBudgetQuotations = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/rfp_budget/quotations/list/`,
    {
      ...getConfig(),
      params: {
        rfp_id: id,
      },
    }
  );

  return data.results;
};

export const addNewBudget = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp_budget/`,
    payload,
    getConfig()
  );

  return data;
};

export const updateBudget = async ({ id, ...payload }) => {
  const { data } = await axios.put(
    `${API_URL}/api/empevents/rfp_budget/${id}/`,
    payload,
    getConfig()
  );

  return data;
};

export const deleteBudget = async id => {
  const { data } = await axios.delete(
    `${API_URL}/api/empevents/rfp_budget/${id}/`,
    getConfig()
  );

  return data;
};

export const getRFPSharedDocs = async rfpId => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/rfp/documents/shared/?rfp_id=${rfpId}`,
    getConfig()
  );
  return data.results;
};

export const getRFPPrivateDocs = async rfpId => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/rfp/documents/private/?rfp_id=${rfpId}`,
    getConfig()
  );
  return data.results;
};

export const uploadRFPDoc = async (docFile, extraOptions) => {
  const config = getConfig();
  config.headers['Content-Type'] = 'multipart/form-data';
  const formData = new FormData();

  formData.append('file', docFile);

  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp/documents/upload/`,
    formData,
    {
      ...config,
      ...extraOptions,
    }
  );

  return data;
};

export const addRFPSharedDoc = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp/documents/shared/`,
    payload,
    getConfig()
  );

  return data;
};

export const addRFPPrivateDoc = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp/documents/private/`,
    payload,
    getConfig()
  );

  return data;
};

export const updateRFPSharedDoc = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp/documents/shared/update`,
    payload,
    getConfig()
  );
  return data;
};

export const updateRFPPrivateDoc = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp/documents/private/update`,
    payload,
    getConfig()
  );
  return data;
};

export const deleteRFPSharedDoc = async payload => {
  // Passing random ID just to satisfy the Backend Requirements
  const { data } = await axios.delete(
    `${API_URL}/api/empevents/rfp/documents/shared/1/`,
    {
      data: payload, // Passing data in body
      ...getConfig(),
    }
  );

  return data;
};

export const deleteRFPPrivateDoc = async payload => {
  // Passing random ID just to satisfy the Backend Requirements
  const { data } = await axios.delete(
    `${API_URL}/api/empevents/rfp/documents/private/1/`,
    {
      data: payload, // Passing data in body
      ...getConfig(),
    }
  );

  return data;
};

export const copyRFPDocuments = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp/documents/copy/`,
    payload,
    getConfig()
  );

  return data;
};

export const moveRFPDocuments = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp/documents/move/`,
    payload,
    getConfig()
  );

  return data;
};

export const updateRFPDocument = async payload => {
  const formData = new FormData();
  formData.append('id', payload.id);
  formData.append('file', payload.file);
  formData.append('type', payload.module_type);

  const config = getConfig();
  config.headers['Content-Type'] = 'multipart/form-data';

  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp/documents/update/`,
    formData,
    getConfig()
  );

  return data;
};

export const getRFPLogisticsDocuments = async rfpId => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/rfp/logistic/documents/?rfp_id=${rfpId}`,
    getConfig()
  );
  return data;
};

export const saveRFPLogisticsDocument = async payload => {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    formData.append(key, value);
  });
  const { data } = await axios.post(
    `${API_URL}/api/empevents/rfp/logistic/documents/`,
    formData,
    getConfig()
  );

  return data;
};

export const getEventCoordinators = async () => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/coordinator/`,
    getConfig()
  );
  return data.results;
};

export const addNewEventCoordinator = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/empevents/coordinator/`,
    payload,
    getConfig()
  );

  return data;
};

export const updateEventCoordinator = async ({ id, ...payload }) => {
  const { data } = await axios.put(
    `${API_URL}/api/empevents/coordinator/${id}/`,
    payload,
    getConfig()
  );

  return data;
};

export const deleteEventCoordinator = async selectedcoordinators => {
  const selectedcoordinatorIds = [...selectedcoordinators].map(({ id }) => id);

  const { data } = await axios.delete(
    `${API_URL}/api/empevents/coordinator/1/`, // Sending static id to satisfy backend requirements
    {
      params: {
        coordinator_ids: [...selectedcoordinatorIds].join(','),
      },
      ...getConfig(),
    }
  );

  return data;
};

export const assignEventCoordinatorToRFPRequest = async ({
  rfpID,
  ...restPayload
}) => {
  const { data } = await axios.put(
    `${API_URL}/api/empevents/add/coordinator/rfp/${rfpID}/`,
    { ...restPayload },
    getConfig()
  );

  return data;
};

export const getRFPProInvoices = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/rfp_pro_invoices/?rfp_id=${id}`,
    getConfig()
  );

  return data.results;
};

export const getSingleProInvoice = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/sales/proInvoices/${id}/`,
    getConfig()
  );

  return data;
};

export const sendRFPProInvoice = async (proInvoiceID, proInvoiceFile) => {
  const formData = new FormData();
  formData.append('pro_invoice_file', proInvoiceFile, proInvoiceFile.name);

  const config = getConfig();
  config.headers['content-type'] = 'multipart/form-data';

  const { data } = await axios.patch(
    `${API_URL}/api/accounting/sales/proinvoice/${proInvoiceID}/upload/send`,
    formData,
    config
  );

  return data;
};

export const deleteMultipleRFPProInvoice = async selectedProInvoices => {
  const targetProInvoiceIDs = selectedProInvoices.map(item => item.id);

  // Sending static id '1' to satisfy backend requirements
  const { data } = await axios.delete(
    `${API_URL}/api/accounting/multiple/proinvoices/delete/1/?pro_invoice_ids=${targetProInvoiceIDs.join(
      ','
    )}`,
    getConfig()
  );

  return data;
};

export const getRFPInvoices = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/rfp_invoices/?rfp_id=${id}`,
    getConfig()
  );

  return data.results;
};

export const getItems = async params => {
  const { data } = await axios.get(`${API_URL}/api/accounting/list/items`, {
    ...getConfig(),
    params,
  });

  return data.results;
};

export const getTrackedItemsOptions = async () => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/tracked/options`,
    getConfig()
  );

  return data;
};

export const getItemDetails = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/items/${id}/`,
    getConfig()
  );

  return data;
};

export const getItemByName = async itemName => {
  // replace & with %26 as its causing API failure
  const modifiedName = itemName.includes('&')
    ? itemName.replace('&', '%26')
    : itemName;
  const { data } = await axios.get(`${API_URL}/api/item/by/name`, {
    ...getConfig(),
    params: {
      item_name: modifiedName,
    },
  });

  return data;
};

export const getItemAjustmentTransactions = async ({ id, ...params }) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/${id}/inventory/transactions`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getItemQuotationTransactions = async ({ id, ...params }) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/${id}/quotation/transactions`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getItemProformaInvoiceTransactions = async ({ id, ...params }) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/${id}/proinvoice/transactions`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getItemInvoiceTransactions = async ({ id, ...params }) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/${id}/invoice/transactions`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getItemCreditNoteTransactions = async ({ id, ...params }) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/${id}/creditnote/transactions`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getItemPurchaseOrderTransactions = async ({ id, ...params }) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/${id}/purorder/transactions`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getItemBillTransactions = async ({ id, ...params }) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/${id}/bill/transactions`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getItemDebitNoteTransactions = async ({ id, ...params }) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/${id}/debitnote/transactions`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getTaxRates = async () => {
  const res = await axios.get(
    `${API_URL}/api/accounting/accountant/taxRates/`,
    getConfig()
  );

  return res.data.results;
};

export const getEventRequests = async params => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/dashboard/rfp/requests/count`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getQuotationsStatus = async params => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/dashboard/quotation/count`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getInvoicesGraphData = async params => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/dashboard/invoice/proinvoice/count`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getTotalEventsGraphData = async params => {
  const { data } = await axios.get(
    `${API_URL}/api/empevents/dashboard/rfp/count`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const saveGlobalDocument = ({ module, ...payload }) => {
  return new Promise(async (resolve, reject) => {
    if (!module) reject();
    if (module === 'documents') {
      try {
        await updateRFPDocument(payload);
        resolve();
      } catch (error) {
        reject();
      }
    }
    reject();
  });
};

export const getUserOptions = async () => {
  const { data } = await axios.get(
    `${API_URL}/api/users/options/`,
    getConfig()
  );

  return data;
};

export const getAllActiveChartOfAccounts = async () => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/accountant/chartOfAccounts/active`,
    getConfig()
  );

  return data.results;
};

export const getCashChartOfAccounts = async () => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/accountant/chartOfAccounts/cash`,
    getConfig()
  );

  return data.results;
};

export const getStockChartOfAccounts = async () => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/accountant/chartOfAccounts/stock`,
    getConfig()
  );

  return data.results;
};

export const updateSalariesStatus = async payload => {
  const { data } = await axios.patch(
    `${API_URL}/api/salaries/update/status/`,
    payload,
    getConfig()
  );

  return data;
};

export const makeSalariesPayment = async payload => {
  const { data } = await axios.patch(
    `${API_URL}/api/salaries/pay/`,
    payload,
    getConfig()
  );

  return data;
};

export const getInventoryAdjustments = async params => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/adjustment/`,
    {
      ...getConfig(),
      params,
    }
  );

  return data;
};

export const getInventoryAdjustmentDetails = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/item/adjustment/${id}/`,
    getConfig()
  );

  return data;
};

export const getInventoryAdjustmentJournal = async id => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/inventory/adjustment/${id}/journals`,
    getConfig()
  );

  return data;
};

export const addInventoryAdjustment = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/accounting/item/adjustment/`,
    payload,
    getConfig()
  );

  return data;
};

export const updateInventoryAdjustment = async ({ id, ...payload }) => {
  const { data } = await axios.put(
    `${API_URL}/api/accounting/item/adjustment/${id}/`,
    payload,
    getConfig()
  );

  return data;
};

export const updateInventoryAdjustmentStatus = async (id, status) => {
  const { data } = await axios.patch(
    `${API_URL}/api/accounting/inventory/adjustment/${id}/status`,
    {
      status,
    },
    getConfig()
  );

  return data;
};

export const deleteInventoryAdjustments = async (
  selectedAdjustmentIds = []
) => {
  const { data } = await axios.delete(
    `${API_URL}/api/accounting/item/multiple/adjustment/delete/`,
    {
      params: {
        adjustment_ids: selectedAdjustmentIds.join(','),
      },
      ...getConfig(),
    }
  );

  return data;
};

export const getAdjustmentReasons = async () => {
  const { data } = await axios.get(
    `${API_URL}/api/lookups/search/adjustment_reason`,
    getConfig()
  );

  return data.results;
};

export const addNewLookup = async payload => {
  const { data } = await axios.post(
    `${API_URL}/api/lookups/`,
    payload,
    getConfig()
  );

  return data;
};

export const deleteLookup = async id => {
  const { data } = await axios.delete(
    `${API_URL}/api/lookups/${id}/`,
    getConfig()
  );

  return data;
};

export const companyBaseInfo = async () => {
  const { data } = await axios.get(
    `${API_URL}/api/company/base/info`,
    getConfig()
  );
  const modifiedData = {
    ...data,
    company_name: capitalizeFirstLetter(data.company_name),
  };
  return modifiedData;
};

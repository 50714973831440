import axios from 'axios';
import moment from 'moment';
import { reset as formReset } from 'redux-form';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';
import { tokenConfig, UPDATE_USER } from './authActions';
// import convertURLToFile from '../../utils/ConvertUrlToFile';

const formatDate = (date, format = 'YYYY-MM-DD') =>
  date ? moment(date).format(format) : null;

export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_BY_STATUS = 'GET_USERS_BY_STATUS';
export const GET_USERS_BY_DEPARTMENT = 'GET_USERS_BY_DEPARTMENT';
export const USER_SIGNATURE = 'USER_SIGNATURE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_RECENT_ACTIVITY = 'GET_RECENT_ACTIVITY';
export const RECORD_ACTIVITY = 'RECORD_ACTIVITY';
export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_USERS = 'CLEAR_USERS';
export const GET_REQUESTOR_SIGNATURE = 'GET_REQUESTOR_SIGNATURE';
export const GET_USER_UPLOADED_DOCUMENT = 'GET_USER_UPLOADED_DOCUMENT';

// GET USER
export const getUser = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/users/${id}/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET USERS
export const getUsers = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/users/`, tokenConfig(getState));
    dispatch({
      type: GET_USERS,
      payload: res.data.results,
    });
  } catch (err) {
    // dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET USERS BY STATUS
export const getUsersByStatus = value => async (dispatch, getState) => {
  let employeStatus = '';
  if (typeof value === 'object') {
    employeStatus = value.status || 'all';
  }
  if (typeof value === 'string') employeStatus = value || 'all';

  try {
    const res = await axios.get(
      `${API_URL}/api/users/search/status/${employeStatus}`,
      {
        ...tokenConfig(getState),
        params: {
          company: value.company,
        },
      }
    );
    dispatch({
      type: GET_USERS_BY_STATUS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET DOCUMENTS UPLOADED BY SPECIFIC USER
export const getUserUploadedDocument = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/profile/register/doc/?user_profile_id=${id}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_USER_UPLOADED_DOCUMENT,
      payload: res.data.results[0],
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD USER
export const addUser = values => async (dispatch, getState) => {
  const {
    user_spouse: userSpouses,
    user_child: userChildren,
    passport_doc: passportDoc,
    visa_doc: visaDoc,
    eid_doc: eidDoc,
    contract_doc: contractDoc,
    medical_insurance_doc: medicalInsuranceDoc,
    group,
    email,
    password,
    ...profileValues
  } = values;

  const profile = {
    ...profileValues,
    imm_super_id: values.imm_super_id ? values.imm_super_id : null,
    dob: formatDate(profileValues.dob),
    passport_expiration_date: formatDate(
      profileValues.passport_expiration_date
    ),
    visa_expiration_date: formatDate(profileValues.visa_expiration_date),
    eid_expiration_date: formatDate(profileValues.eid_expiration_date),
    medical_insurance_expiration_date: formatDate(
      profileValues.medical_insurance_expiration_date
    ),
    status: 'active',
  };

  const hasAnyChildInUAE = userChildren.find(item => item.liv_in_uae === 'Yes');

  const modifiedUserSpousesData = userSpouses.map(item => ({
    ...item,
    birthdate: formatDate(item.birthdate),
  }));
  const modifiedUserChildrenData = userChildren.map(item => ({
    ...item,
    birthdate: formatDate(item.birthdate),
  }));

  const user = {
    profile,
    has_any_child_in_uae: Boolean(hasAnyChildInUAE),
    user_spouse:
      values.marital_status === 'Married' ? modifiedUserSpousesData : undefined,
    user_child:
      values.marital_status === 'Married'
        ? modifiedUserChildrenData
        : undefined,
    user_groups: group ? [group] : [],
    email,
    password,
  };

  const formDataConfig = tokenConfig(getState);
  formDataConfig.headers['Content-Type'] = 'multipart/form-data';

  try {
    const res = await axios.post(
      `${API_URL}/api/auth/register`,
      user,
      tokenConfig(getState)
    );

    // Adding User Documents
    const formData = new FormData();
    if (res.data.user && res.data.user.profile.id)
      formData.append('user_profile_id', res.data.user.profile.id);
    // Target first object from array of file because logic is implemented in such way
    if (values.passport_num && passportDoc)
      formData.append('passport', passportDoc);
    if (values.visa_num && visaDoc) formData.append('visa', visaDoc);
    if (values.eid_num && eidDoc) formData.append('eid', eidDoc);
    if (values.contract_type && contractDoc)
      formData.append('contract', contractDoc);
    if (values.medical_insurance_num && medicalInsuranceDoc)
      formData.append('medical_insurance', medicalInsuranceDoc);

    await axios.post(
      `${API_URL}/api/profile/register/doc/`,
      formData,
      formDataConfig
    );
    dispatch(formReset('ADD_EMPLOYEE')); // clear form on success
    dispatch(createMessage({ message: 'Employee Added' }));
    dispatch({
      type: ADD_USER,
      payload: res.data.user,
    });
  } catch (err) {
    throw err;
  }
};

// UPLOAD PROFILE PHOTO
export const uploadProfilePhoto =
  (values, currentUser, user) => async (dispatch, getState) => {
    const formData = new FormData();
    formData.append('photo', values.photo[0], values.photo[0].name);
    formData.append('user_id', currentUser.id);
    const config = tokenConfig(getState);
    config.headers['content-type'] = 'multipart/form-data';
    try {
      const res = await axios.patch(
        `${API_URL}/api/auth/uploadPhoto`,
        formData,
        tokenConfig(getState)
      );

      dispatch(createMessage({ message: 'Profile Picture Uploaded' }));

      // implemented so HR can change employee profile picutre
      dispatch({
        type: user.id === currentUser.id ? UPDATE_USER : UPDATE_USER_PROFILE,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };
export const updateUploadedDocuments = values => async (dispatch, getState) => {
  const {
    docs_id: docsId,
    passport_doc: passportDoc,
    passport_num: passportNum,
    // passport_doc_link:passportLink,
    user_profile_id: userProfileId,
    visa_doc: visaDoc,
    eid_doc: eidDoc,
    medical_insurance_doc: medicalDoc,
    contract_doc: contractDoc,

    eid_num: eidNum,
    visa_num: visaNum,
    medical_insurance_num: medicalNum,
  } = values;
  let payload = {};
  const formDataConfig = tokenConfig(getState);
  const formData = new FormData();
  const passport = passportNum === '';
  const eid = eidNum === '';
  const visa = visaNum === '';
  const medical = medicalNum === '';

  const isFilesRemoved = passport && eid && visa;

  const isPassportRemoved = !isFilesRemoved && passport;

  const isEidRemoved = !isFilesRemoved && eid;
  const isVisaRemoved = !isFilesRemoved && visa;

  if (isFilesRemoved) {
    payload = {
      visa: null,
      passport: null,
      eid: null,
      user_profile_id: userProfileId,
    };
  }

  if (!isFilesRemoved) {
    formDataConfig.headers['Content-Type'] = 'multipart/form-data';
    formData.append('user_profile_id', userProfileId);

    if (isPassportRemoved) {
      formData.append('passport', '');
      payload = formData;
    }

    if (passportNum && !isPassportRemoved) {
      formData.append('passport', passportDoc);
      payload = formData;
    }
    if (isVisaRemoved) {
      formData.append('visa', '');
      payload = formData;
    }

    if (visaNum && !isVisaRemoved) {
      formData.append('visa', visaDoc);
      payload = formData;
    }
    if (isEidRemoved) {
      formData.append('eid', '');
      payload = formData;
    }
    if (eidNum && !isEidRemoved) {
      formData.append('eid', eidDoc);
      payload = formData;
    }

    if (medical) {
      formData.append('medical_insurance', '');
      payload = formData;
    }
    if (medicalNum && !medical) {
      formData.append('medical_insurance', medicalDoc);
      payload = formData;
    }

    if (values.contract_type && typeof contractDoc === 'object') {
      formData.append('contract', contractDoc);
      payload = formData;
    }
  }
  try {
    let res;
    if (docsId) {
      res = await axios.patch(
        `${API_URL}/api/profile/register/doc/${docsId}/`,
        payload,
        formDataConfig
      );
    } else {
      res = await axios.post(
        `${API_URL}/api/profile/register/doc/`,
        payload,
        formDataConfig
      );
    }

    dispatch({
      type: GET_USER_UPLOADED_DOCUMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// UPDATE PERSONAL DATA
export const updatePersonalData = values => async (dispatch, getState) => {
  const {
    passport_doc: passportDoc,
    visa_doc: visaDoc,
    eid_doc: eidDoc,
    contract_doc: contractDoc,
    medical_insurance_doc: medicalInsuranceDoc,
    id,
    user_spouse: userSpouses,
    user_child: userChildren,
    ...profileValues
  } = values;

  const hasAnyChildInUAE =
    profileValues.marital_status === 'Married' &&
    Boolean(userChildren.find(item => item.liv_in_uae === 'Yes'));

  const modifiedUserSpousesData = userSpouses.map(item => ({
    ...item,
    birthdate: formatDate(item.birthdate),
  }));
  const modifiedUserChildrenData = userChildren.map(item => ({
    ...item,
    birthdate: formatDate(item.birthdate),
  }));
  const user = {
    profile: {
      ...profileValues,
      dob: formatDate(profileValues.dob),
      passport_expiration_date: formatDate(
        profileValues.passport_expiration_date
      ),
      visa_expiration_date: formatDate(profileValues.visa_expiration_date),
      eid_expiration_date: formatDate(profileValues.eid_expiration_date),
    },
    has_any_child_in_uae: hasAnyChildInUAE,
    user_spouse:
      values.marital_status === 'Married' ? modifiedUserSpousesData : undefined,
    user_child:
      values.marital_status === 'Married'
        ? modifiedUserChildrenData
        : undefined,
  };

  try {
    const res = await axios.patch(
      `${API_URL}/api/users/${id}/`,
      user,
      tokenConfig(getState)
    );

    dispatch(createMessage({ message: 'Employee Information Updated' }));
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// UPDATE EMPLOYMENT DATA
export const updateEmploymentData = values => async (dispatch, getState) => {
  const user = {
    profile: {
      ...values,
      medical_insurance_expiration_date: formatDate(
        values.medical_insurance_expiration_date
      ),
      start_date: formatDate(values.start_date),
      notice_period_start_date: formatDate(values.notice_period_start_date),
      end_date: formatDate(values.end_date),
    },
  };

  try {
    const res = await axios.patch(
      `${API_URL}/api/users/${values.id}/`,
      user,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Employee Information Updated' }));
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// UPDATE ACCOUNT DATA
export const updateAccountData = values => async (dispatch, getState) => {
  const changePasswordPayload = {};
  if (values.new_password) {
    changePasswordPayload.password = values.new_password;
    changePasswordPayload.user_id = values.id;
  }

  const profile = {
    system_access: values.system_access,
    company: values.company,
    department: values.department,
    imm_super_id: values.imm_super_id ? values.imm_super_id : null,
  };
  const user = {
    profile,
    user_groups: values.group ? [values.group] : [],
    email: values.email,
  };
  try {
    const res = await axios.patch(
      `${API_URL}/api/users/${values.id}/`,
      user,
      tokenConfig(getState)
    );
    if (values.new_password)
      await axios.patch(
        `${API_URL}/api/auth/hr/change/password`,
        changePasswordPayload,
        tokenConfig(getState)
      );
    dispatch(createMessage({ message: 'Employee Information Updated' }));
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE USER
export const deleteUser = id => async (dispatch, getState) => {
  try {
    await axios.delete(`${API_URL}/api/users/${id}/`, tokenConfig(getState));
    dispatch({
      type: DELETE_USER,
      payload: id,
    });
    dispatch(createMessage({ message: 'Employee Deleted Successfully' }));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET USER
export const getImmediateSupervisors =
  (department, company) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/ImmediateSupervisor/immediate_supervisor/?department=${department}&company=${company}`,
        tokenConfig(getState)
      );
      dispatch({
        type: GET_USERS_BY_DEPARTMENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

// GET USER RECENT ACTIVITY
export const getRecentActivity = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/user/recent/activity/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_RECENT_ACTIVITY,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// Record new Activity
export const recordActivity = values => async (dispatch, getState) => {
  const data = {
    type: values.type,
    description: values.description,
    title: values.title,
    object_id: values.id,
    user: getState().auth.user.id,
  };

  try {
    const res = await axios.post(
      `${API_URL}/api/user/recent/activity/`,
      data,
      tokenConfig(getState)
    );
    dispatch({
      type: RECORD_ACTIVITY,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const clearUser = () => ({
  type: CLEAR_USER,
});

export const clearUsers = () => ({
  type: CLEAR_USERS,
});

// UPLOAD PROFILE PHOTO
export const updateStampAndSignature = values => async (dispatch, getState) => {
  const formData = new FormData();
  const isStampChanged = typeof values.stamp !== 'string';
  const isSignChanged = typeof values.signature !== 'string';
  if (isStampChanged && values.stamp)
    formData.append('stamp', values.stamp[0], values.stamp[0].name);
  if (isSignChanged && values.signature)
    formData.append('signature', values.signature[0], values.signature[0].name);
  const config = tokenConfig(getState);
  config.headers['content-type'] = 'multipart/form-data';
  if (!values.stamp) formData.append('remove_stamp', true);
  if (!values.signature) formData.append('remove_signature', true);

  try {
    const res = await axios.patch(
      `${API_URL}/api/auth/uploadStamp`,
      formData,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Stamp and Signature Uploaded' }));
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const uploadRequestorSignature =
  values => async (dispatch, getState) => {
    const formData = new FormData();
    if (values.requestor_signature)
      formData.append('requestor_signature', values.requestor_signature[0]);

    if (!values.requestor_signature) formData.append('remove_signature', true);
    const config = tokenConfig(getState);
    config.headers['content-type'] = 'multipart/form-data';
    try {
      const res = await axios.patch(
        `${API_URL}/api/auth/upload/requestor/signature`,
        formData,
        tokenConfig(getState)
      );
      dispatch(createMessage({ message: 'Signature Uploaded' }));
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

import {
  GET_ITEM,
  CLEAR_ITEM,
  GET_ITEMS,
  DELETE_ITEM,
  ADD_ITEM,
  EDIT_ITEM,
  ITEMS_LOADING,
  ITEMS_LOADED,
  CHANGE_ITEM_STATUS,
} from '../actions/itemsActions';

const initialState = {
  count: null,
  items: [],
  fetched: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ITEM:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_ITEM:
      return {
        ...state,
        fetched: null,
      };
    case GET_ITEMS:
      return {
        ...state,
        count: action.payload.count,
        items: action.payload.results,
      };
    case DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
        count: state.count - 1,
      };
    case ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
        count: state.count + 1,
      };
    case EDIT_ITEM:
      return {
        ...state,
      };
    case ITEMS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ITEMS_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case CHANGE_ITEM_STATUS:
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    default:
      return state;
  }
};

import {
  GET_INVOICE,
  CLEAR_INVOICE,
  GET_INVOICES,
  DELETE_INVOICE,
  ADD_INVOICE,
  VOID_INVOICE,
  EDIT_INVOICE,
  EDIT_INVOICE_NOTE,
  DELETE_INVOICE_NOTE,
  GET_INVOICE_DOCS,
  ADD_INVOICE_DOCS,
  DELETE_INVOICE_DOC,
  MARK_UNPAID,
  GET_INVOICE_JOURNAL,
  CLEAR_INVOICE_JOURNAL,
  GET_INVOICE_PAYMENTS,
  DELETE_CREDIT_APPLIED_PAYMENT,
  CLEAR_INVOICE_PAYMENTS,
  INVOICE_LOADING,
  INVOICE_LOADED,
  GET_INVOICES_LIST,
} from '../actions/invoicesActions';

const initialState = {
  count: null,
  invoicesList: [],
  invoices: [],
  currentInvoiceDocs: [],
  invoiceJournal: null,
  fetched: null,
  invoicePayments: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICE:
      return {
        ...state,
        fetched: action.payload,
      };
    case VOID_INVOICE:
      return {
        ...state,
        fetched: action.payload,
      };
    case CLEAR_INVOICE:
      return {
        ...state,
        fetched: null,
      };
    case GET_INVOICES_LIST:
      return {
        ...state,
        count: action.payload.count,
        invoicesList: action.payload.results,
      };
    case GET_INVOICES:
      return {
        ...state,
        count: action.payload.count,
        invoices: action.payload.results,
      };
    case DELETE_INVOICE:
      return {
        ...state,
        invoicesList: state.invoicesList.filter(
          invoice => invoice.id !== action.payload
        ),
      };
    case ADD_INVOICE:
      return {
        ...state,
        invoicesList: [...state.invoicesList],
      };
    case EDIT_INVOICE:
      return {
        ...state,
        // invoices: [...state.invoices, action.payload],
      };
    case EDIT_INVOICE_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            invoice_notes: state.fetched.invoice_notes.map(note => {
              if (note.id === action.payload.id) return action.payload;
              return note;
            }),
          },
        },
      };
    case DELETE_INVOICE_NOTE:
      return {
        ...state,
        fetched: {
          ...state.fetched,
          ...{
            invoice_notes: state.fetched.invoice_notes.filter(
              note => note.id !== action.payload
            ),
          },
        },
      };
    case GET_INVOICE_DOCS:
      return {
        ...state,
        currentInvoiceDocs: action.payload,
      };
    case ADD_INVOICE_DOCS:
      return {
        ...state,
        currentInvoiceDocs: [...state.currentInvoiceDocs, action.payload],
      };
    case DELETE_INVOICE_DOC:
      return {
        ...state,
        currentInvoiceDocs: state.currentInvoiceDocs.filter(
          doc => doc.id !== action.payload
        ),
      };
    case MARK_UNPAID:
      return {
        ...state,
        fetched: action.payload,
      };
    case GET_INVOICE_JOURNAL:
      return {
        ...state,
        invoiceJournal: action.payload,
      };
    case CLEAR_INVOICE_JOURNAL:
      return {
        ...state,
        invoiceJournal: null,
      };
    case GET_INVOICE_PAYMENTS:
      return {
        ...state,
        invoicePayments: action.payload,
      };
    case DELETE_CREDIT_APPLIED_PAYMENT:
      return {
        ...state,
        invoicePayments: {
          ...state.invoicePayments,
          credits_applied: state.invoicePayments.credits_applied.filter(
            item => item.id !== action.payload
          ),
        },
      };
    case CLEAR_INVOICE_PAYMENTS:
      return {
        ...state,
        invoicePayments: null,
      };
    case INVOICE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case INVOICE_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

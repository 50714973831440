import {
  USER_LOADED,
  UPDATE_USER,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  GLOBAL_STATE,
} from '../actions/authActions';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  isLoading: false,
  user: null,
  companyDetail: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      return state;
    // return {
    //   token: null,
    //   user: null,
    //   isAuthenticated: false,
    //   isLoading: false,
    // };
    case GLOBAL_STATE:
      return {
        ...state,
        companyDetail: action.payload,
      };
    default:
      return state;
  }
}
